import React from 'react'
import {Tooltip} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

interface TooltipWarningProps {
  tooltipMessage: string | undefined
}

export default function TooltipWarning({tooltipMessage} : TooltipWarningProps) {
  return (
    <Tooltip title={tooltipMessage ? tooltipMessage : ''}>
      <WarningIcon color={"warning"}/>
    </Tooltip>
  )
}
