import React from 'react';
import { Typography } from '@mui/material';

interface PageTitleProps {
  children: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ children }) => {
  return (
    <Typography variant="h3">{children}</Typography>
  );
};

export default PageTitle;
