const empty = ""
const customerName = "Kundenname"
const tmzActiveSince = "TMZ aktiv seit"
const customerSystem = "Kundensystem"
const customerNumber = "Kundennummer"
const tin = "TIN"
const externalSensors = "Externe Sensoren"
const totalActiveImplants = "Alle aktiven Implantate"
const discount = "Rabatt"
const externalSensorsPrice = externalSensors + " Preis"
const totalActiveImplantsPrice = totalActiveImplants + " Preis"
const externalSensorsTotalPrice = externalSensors + " Gesamtpreis"
const totalActiveImplantsTotalPrice = totalActiveImplants + " Gesamtpreis"
const getemed = "Getemed"
const biotronikExternalSensors = "Biotronik " + externalSensors
const marge = "Marge"
const specificMargeDistribution = "Spezifische Verteilung der " + marge
const getemedBill = getemed + " Rechnung"
const semdatexBioBill = "SEMDATEX an Biotronik gestellte Rechnung"
const docCirrusPart = "DocCirrus Anteil"
const semdatex = "SEMDATEX"
const bioVPImplants = "Biotronik VP Implantate"
const billCreated = "Bisher in Rechnung gestellt"
const priceToBeInBill = "Rechnung an Biotronik"
const additionalDemand = "Nachforderung"

export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

export const fixCosts = "Fixkosten"
export const hotlineExtra = "Hotline extra"
export const euro = "€"
export const perEuro = euro + "/Stück"
export const price = "Preis"
export const recalculation = "Nachberechnung"
export const amount = "Betrag"
export const difference = "Differenz"
export const alt = "Alt"

//For Merged cells the last cell has to have the merged cells constant as name, for the first ones use empty
export const tableHeaderConstants = [
  'Biotronik Kundennummer',
  customerName,
  tmzActiveSince,
  customerSystem,
  customerNumber,
  tin,
  externalSensors,
  totalActiveImplants,
  discount,
  externalSensorsPrice,
  totalActiveImplantsPrice,
  externalSensorsTotalPrice,
  totalActiveImplantsTotalPrice,
  empty, empty, empty, empty, getemed,
  biotronikExternalSensors,
  empty, marge,
  empty, empty, empty, empty, empty, empty, empty, specificMargeDistribution,
  marge,
  empty, getemedBill,
  empty, empty, empty, empty, semdatexBioBill,
  empty, docCirrusPart,
  empty,
  semdatex,
  empty, bioVPImplants,
  empty, marge,
  empty, empty, empty, empty, empty, specificMargeDistribution,
  marge, empty,
  empty, billCreated,
  empty, priceToBeInBill,
  additionalDemand,
  docCirrusPart
]

export const excelDesigns = {
  oddBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "FFE699"
      },
    },
    alignment: {
      wrapText: true, shrinkToFit: true,
    }
  },
  lightYellowBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "FFFF00"
      },
    },
  },
  darkYellowBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "C5C500"
      },
    },
  },
  darkGreenBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "A9D18E"
      },
    },
  },
  blueBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "ADB9CA"
      }
    }
  },
  lightGreenBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "C5E0B4"
      }
    }
  },
  tableBorderLeft: {
    border: {
      left: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
    }
  },
  tableBorderRight: {
    border: {
      right: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
    }
  },
  tableBorderLeftRight: {
    border: {
      right: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
      left: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
    }
  },
  tableBorderTop: {
    border: {
      top: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
    }
  },
  tableBorderBottom: {
    border: {
      bottom: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
    }
  },
  tableBorder: {
    border: {
      bottom: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
      right: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
      left: {
        style: "thick",
        color: {
          argb: "000000"
        }
      },
    }
  },
  redBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "FF0000"
      },
    },
  },
  darkBrownBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "F4B183"
      }
    }
  },
  lightBrownBackground: {
    fill: {
      type: "pattern",
      pattern: "darkDown",
      fgColor: {
        argb: "F8CBAD"
      }
    }
  }
}
