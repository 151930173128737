import {formulaPrefix} from "./BillingService";
import {alt, amount, difference, euro, fixCosts, hotlineExtra, perEuro, price, recalculation} from "./ExcelConstants";
import {setDesigns} from "./ExcelTableCreator";

/**
 * Fills a row fromCell cell x toCell cell y with data fromCell an object
 *
 * @param row toCell fill
 * @param fromCell start point
 * @param toCell end point
 * @param fromO object to start iterating from
 * @param object data toCell fill
 */
function fillRow(row, fromCell, toCell, object, fromO) {
    let cellOffset = 1

    if (fromCell !== 0) {
        fromCell--;
        cellOffset++;
    }

    for (let index = fromCell; index < toCell; index++) {
        const value = object[fromO !== undefined ? fromO : index]
        const cell = row.getCell(index + cellOffset)

        cell.style = {
            ...cell.style,
            alignment: {
                shrinkToFit: true,
                wrapText: true,
                horizontal: "center",
                vertical: "middle",
            },
            protection: {
                locked: true
            }
        }
        if (typeof value === 'string') {
            if (value.includes(formulaPrefix)) {
                cell.value = {
                    formula: value.replace(formulaPrefix, "")
                }
            } else {
                cell.value = String(value)
            }
        } else if (typeof value === 'number') {
            cell.value = Number(value)
        } else {
            cell.value = String(value)
        }

        if (fromO !== undefined) {
            fromO++;
        }
    }
}
export function createSubTables(sheet, config) {
  function mergeVertically(cell) {
    sheet.mergeCells(cell + "3:" + cell + "4");
  }

  const mergeCellsRanges = [
    "Q3:R3", "AI3:AK3"
  ];

  const verticallyMergedCells = [
    "J", "K", "L", "M", "P", "S", "T", "U", "W", "Y", "AA", "AB", "AC", "AD", "AO", "AP", "AR", "AS", "AU", "AW", "AY", "AZ", "BB", "BC", "BD", "BE", "BF", "BG", "BH"
  ];

  const cellValues = [
    { cell: "J4", value: perEuro },
    { cell: "K4", value: perEuro },
    { cell: "L4", value: euro },
    { cell: "M4", value: euro },
    { cell: "O3", value: fixCosts },
    { cell: "O4", value: perEuro },
    { cell: "P3", value: euro },
    { cell: "Q4", value: perEuro },
    { cell: "R3", value: hotlineExtra },
    { cell: "R4", value: euro },
    { cell: "S4", value: perEuro },
    { cell: "T4", value: perEuro },
    { cell: "U4", value: euro },
    { cell: "V3", value: "SEMDATEX" },
    { cell: "V4", value: `${config.revenueShare.semdatex}%` },
    { cell: "W4", value: euro },
    { cell: "X3", value: "GETEMED" },
    { cell: "X4", value: `${config.revenueShare.getemed}%` },
    { cell: "Y4", value: euro },
    { cell: "Z3", value: "BIOTRONIK" },
    { cell: "Z4", value: `${config.revenueShare.biotronik}%` },
    { cell: "AA4", value: euro },
    { cell: "AB3", value: "DOCCIRRUS" },
    { cell: "AB4", value: `${config.revenueShare.docCirrus}%` },
    { cell: "AC4", value: euro },
    { cell: "AD4", value: perEuro },
    { cell: "AE3", value: price },
    { cell: "AE4", value: perEuro },
    { cell: "AF3", value: amount },
    { cell: "AF4", value: euro },
    { cell: "AG3", value: alt },
    { cell: "AG4", value: price },
    { cell: "AH3", value: alt },
    { cell: "AH4", value: amount },
    { cell: "AK3", value: recalculation },
    { cell: "AI4", value: price },
    { cell: "AJ4", value: amount },
    { cell: "AK4", value: difference },
    { cell: "AL3", value: price },
    { cell: "AL4", value: perEuro },
    { cell: "AM3", value: amount },
    { cell: "AM4", value: euro },
    { cell: "AO4", value: fixCosts },
    { cell: "AP4", value: perEuro },
    { cell: "AQ3", value: amount },
    { cell: "AQ4", value: euro },
    { cell: "AR4", value: perEuro },
    { cell: "AS4", value: euro },
    { cell: "AT3", value: "SEMDATEX" },
    { cell: "AT4", value: `${config.implantRevenueShare.semdatex}%` },
    { cell: "AU4", value: euro },
    { cell: "AV3", value: "Biotronik" },
    { cell: "AV4", value: `${config.implantRevenueShare.biotronik}%` },
    { cell: "AW4", value: euro },
    { cell: "AX3", value: "DocCirrus" },
    { cell: "AX4", value: `${config.implantRevenueShare.docCirrus}%` },
    { cell: "AY4", value: euro },
    { cell: "AZ4", value: "100%" },
    { cell: "BB4", value: perEuro },
    { cell: "BC4", value: euro },
    { cell: "BD4", value: perEuro },
    { cell: "BE4", value: euro },
    { cell: "BF4", value: euro },
    { cell: "BG4", value: perEuro },
    { cell: "BH4", value: euro }
  ];

  mergeCellsRanges.forEach(range => sheet.mergeCells(range));
  verticallyMergedCells.forEach(cell => mergeVertically(cell));
  cellValues.forEach(({ cell, value }) => sheet.getCell(cell).value = value);
}

/**
 * Fills the sheet with all data extracted from our customers
 *
 * @param sheet the used sheets
 * @param customerInfo customer info containing details for Excel
 */
export async function fillExcelTables(sheet, customerInfo) {
    let rowIndex = 5;

    for (rowIndex; rowIndex < 5 + customerInfo.length; rowIndex++) {
        const row = sheet.getRow(rowIndex)
        const object = Object.values(customerInfo[rowIndex - 5])

        // Mengengerüst & BIO-Verkaufspreise
        fillRow(row, 0, 13, object)

        //Externe Sensoren
        fillRow(row, 14, 38, object)

        //Implantate
        fillRow(row, 40, 51, object, 38)

        //Abrechnungen der Implantate für die Vergangenheit
        fillRow(row, 53, 59, object, 50)
    }

    setDesigns(sheet)
}
