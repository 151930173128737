import {addDevice, deleteDevice, getDevices, updateDevice} from "../api/devices";
import {InPaceProductFormValues} from "../components/forms/productForms/InPaceProductForm";
import {Device, deviceSchema, InPaceTableValues} from "../models/device";
import dayjs from "dayjs";
import myzod from "myzod";
import CustomerService from "./CustomerService";
import {formatDate} from "../utils/helperFunctions";

export default class InPaceService {
  static async getInPaceDevices(query?: any): Promise<Device[]> {
    const devices = await getDevices(query)

    if (!Array.isArray(devices)) {
      throw new Error("Fetched inPaceDevices are not valid")
    }

    return devices
  }

  static async addDevice(inPaceDevice: Device) {
    const result = deviceSchema.try(inPaceDevice)

    if (result instanceof myzod.ValidationError) {
      throw new Error('Validation Error, it appears the given object is not a device')
    }

    if (!(await this.checkUniqueProperties(inPaceDevice))) {
      alert("Some values which must be unique are not unique! Device will not be added")
      throw new Error("inPace values are not unique!")
    }

    console.log('Validation passed. Adding device')
    await addDevice(inPaceDevice)
  }

  static async updateDevice(inPaceDevice: Device) {
    const result = deviceSchema.try(inPaceDevice)

    if (result instanceof myzod.ValidationError) {
      throw result
    }

    if (!(await this.checkUniqueProperties(inPaceDevice))) {
      alert("Some values which must be unique are not unique! Device will not be updated")
      throw new Error("inPace values are not unique!")
    }

    console.log('Validation passed. Updating device')
    await updateDevice(inPaceDevice)
  }

  static async deleteDevice(inPaceDeviceId: string) {
    await deleteDevice(inPaceDeviceId)
  }

  static isLicenceValid(startDate: string, endDate: string) {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    return today >= start && today <= end;
  }

  static async getTableDeviceData(): Promise<InPaceTableValues[]> {
    const devices = await getDevices()
    const customers = await CustomerService.getAllCustomers()
    return devices.map(elem => {
      if (!elem._id) {
        throw new Error(`InPace device: ${String(elem)} does not have property ._id\n` +
          "This is necessary to update or delete an inPace device.")
      }

      const regardingCustomer = customers.find((customer) => customer._id === elem.customerId)

      if (regardingCustomer === undefined) {
        throw new Error(`Customer object of inPace device: ${JSON.stringify(elem)} could not be found!`)
      }

      return {
        _id: elem._id,
        customerId: elem.customerId,
        customerName: regardingCustomer.name,
        customerNumber: regardingCustomer.customerNumber,
        location: regardingCustomer.address.location,
        serialNr: elem.serialNr,
        softwareVn: elem.softwareVn,
        manufacturer: elem.manufacturer,
        deviceName: elem.deviceName,
        licenceStart: formatDate(elem.licenceStart),
        licenceEnd: formatDate(elem.licenceEnd),
        dateOfActivation: formatDate(elem.dateOfActivation),
        licenceValid: InPaceService.isLicenceValid(elem.licenceStart, elem.licenceEnd),
        additionalNotes: elem.additionalNotes,
        soldToBiotronik: elem.soldToBiotronik,
        deviceType: elem.deviceType,
        numberOfSubdevices: elem.subDevice.length
      }
    })
  }

  static mapFormObjectToDeviceObject(customerId: string, formObject: InPaceProductFormValues, deviceId?: string): Device {
    return {
      ...deviceId ? {_id: deviceId} : {},
      additionalNotes: formObject.additionalNotes,
      customerId,
      dateOfActivation: formObject.dateOfActivation ? formObject.dateOfActivation.format('YYYY-MM-DD') : "",
      deviceName: formObject.deviceName,
      deviceType: formObject.deviceType,
      licenceEnd: formObject.licenceEnd ? formObject.licenceEnd.format('YYYY-MM-DD') : "",
      licenceStart: formObject.licenceStart ? formObject.licenceStart.format('YYYY-MM-DD') : "",
      manufacturer: formObject.manufacturer,
      serialNr: formObject.serialNr,
      softwareVn: formObject.softwareVn,
      soldToBiotronik: formObject.soldToBiotronik,
      subDevice: formObject.subDevice
    }
  }

  static mapDeviceObjectToFormObject(data: Device): InPaceProductFormValues {
    return {
      additionalNotes: data.additionalNotes,
      dateOfActivation: dayjs(data.dateOfActivation),
      deviceName: data.deviceName,
      deviceType: data.deviceType,
      licenceEnd: dayjs(data.licenceEnd),
      licenceStart: dayjs(data.licenceStart),
      manufacturer: data.manufacturer,
      serialNr: data.serialNr,
      softwareVn: data.softwareVn,
      soldToBiotronik: data.soldToBiotronik,
      subDevice: data.subDevice
    }
  }

  /**
   * This function checks properties which must be unique in the inPace object.
   * At the moment, this is only the "serialNr" property.
   * Returns true if values are unique, false otherwise.
   * @param device
   */
  static async checkUniqueProperties(device: Device): Promise<boolean> {
    const result = await getDevices()
    const notUnique = result.find((elem: any) => {
      return elem.serialNr === device.serialNr && elem._id !== device._id
    })
    return !notUnique
  }
}
