import React, {useEffect} from 'react'
import {Controller, useForm} from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel, MenuItem, Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {Dayjs} from 'dayjs';
import {DataGrid} from "@mui/x-data-grid";
import SubDeviceDialogButton, {SubDeviceFormValues} from "../../dialogs/SubDeviceDialogButton";
import 'dayjs/locale/en-gb';
import {FormIds, subDeviceColumns} from "../../../constants";
import LMDatePicker from "../../subcomponents/LMDatePicker";

export interface InPaceProductFormValues {
  deviceName: string
  serialNr: string
  deviceType: string
  manufacturer: string
  softwareVn: string
  licenceStart: Dayjs | null
  licenceEnd: Dayjs | null
  dateOfActivation: Dayjs | null
  additionalNotes: string
  subDevice: SubDeviceFormValues[]
  soldToBiotronik: boolean
}

export interface InPaceProductFormProps {
  onSubmit: (data: InPaceProductFormValues) => void,
  /** This function updates the dirty state in the parents component */
  updateDirty: (dirty: boolean) => void,
  defaultValues: InPaceProductFormValues
}

export default function InPaceProductForm({onSubmit, defaultValues, updateDirty}: Readonly<InPaceProductFormProps>) {

  const form = useForm<InPaceProductFormValues>({
    defaultValues
  })
  const {register, handleSubmit, formState, getValues, setValue, control, watch} = form
  const {errors} = formState

  const [selectedSubDevice, setSelectedSubDevice] = React.useState([]);
  const [subDevices, setSubDevices] = React.useState(getValues().subDevice)

  const watchedDeviceType = watch('deviceType', getValues().deviceType) // Triggers rerender on changed values in select

  useEffect(() => {
    updateDirty(form.formState.isDirty)
  }, [form.formState.isDirty])

  function handleSubDeviceSelectionChange(selectionModel: any) {
    // Behaviour like a radio button, only onw row is selectable
    let newSelectionModel = selectedSubDevice.length === 0 ? selectionModel : selectionModel.slice(1)
    setSelectedSubDevice(newSelectionModel)
  }

  function getEmptySubDeviceForm(): SubDeviceFormValues {
    return {
      subDeviceNo: "",
      einstellung: "",
      subDeviceSoftwareVn: ""
    }
  }

  function findSelectedSubDevice(): SubDeviceFormValues {
    const subDeviceCopy = [...getValues().subDevice]
    const item = subDeviceCopy.find((elem) => (elem.subDeviceNo === selectedSubDevice[0]))
    if (item === undefined) {
      throw Error("Subdevice could not be found")
    }
    return item
  }

  function addSubDevice(formValues: SubDeviceFormValues) {
    const subDeviceCopy = [...getValues().subDevice]
    subDeviceCopy.push(formValues)
    setValue("subDevice", subDeviceCopy)
    setSubDevices(subDeviceCopy)
  }

  function updateSubDevice(formValues: SubDeviceFormValues) {
    const subDeviceCopy = [...getValues().subDevice]
    for (let i = 0; i < subDeviceCopy.length; i++) {
      if (subDeviceCopy[i].subDeviceNo === selectedSubDevice[0]) {
        subDeviceCopy[i] = formValues
      }
    }
    setValue("subDevice", subDeviceCopy)
    setSubDevices(subDeviceCopy)
  }

  function deleteSubDevice() {
    const subDeviceCopy = [...getValues().subDevice]
    const newSubDevices = subDeviceCopy.filter(subDevice => subDevice.subDeviceNo !== selectedSubDevice[0])
    setValue("subDevice", newSubDevices)
    updateDirty(true)
    setSelectedSubDevice([])
    setSubDevices(newSubDevices)
  }

  function subDeviceButtonDisabled() {
    return selectedSubDevice.length !== 1
  }

  /**
   * Checks if subDeviceNo can be set, because this is the identifier of the subDevice
   * @param id
   */
  function validateSubDeviceId(id: string) {
    const subDeviceCopy = [...getValues().subDevice]

    for (const subDevice of subDeviceCopy) {
      if (subDevice.subDeviceNo === id) {
        return false
      }
    }
    return true
  }

  return (
    <form id={FormIds.updateInPace} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={2} direction={"column"} display={'flex'} justifyContent={"space-between"} width={'100%'}
            sx={{
              marginTop: 2,
              paddingTop: 2,
              paddingLeft: 2,
              paddingRight: 2,
              flexFlow: 'row wrap'
            }}>
        {/* Left form element*/}
        <Grid display={'flex'} flexWrap={'unset'} flexDirection={'column'} width={'50%'}>
          <div style={{
            display: 'flex',
            flexWrap: 'unset',
            flexDirection: 'column',
            marginLeft: '1rem',
            marginRight: '2rem',
            marginBottom: '1rem'
          }}>
            <TextField
              id={"serialNr"}
              variant="standard"
              label="Seriennummer"
              {...register("serialNr", {
                required: "Seriennnummer wird benötigt"
              })}
              required
              sx={{marginTop: 1}}
              error={!!errors.serialNr}
              helperText={errors.serialNr?.message}
            />
            <TextField
              sx={{marginTop: 1}}
              id={"deviceName"}
              variant="standard"
              required
              label="Gerätename"
              {...register("deviceName", {
                required: "Gerätename wird benötigt"
              })}
              error={!!errors.deviceName}
              helperText={errors.deviceName?.message}
            />
            <TextField
              sx={{marginTop: 1}}
              id={"manufacturer"}
              variant="standard"
              disabled
              label="Hersteller"
              {...register("manufacturer", {
                required: "Hersteller wird benötigt"
              })}
              error={!!errors.manufacturer}
              helperText={errors.manufacturer?.message}
            />
          </div>
          <Grid container display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Grid spacing={2} height={'15rem'} display={'flex'} marginBottom={'1rem'}
                  alignItems={'flex'} justifyContent={'space-evenly'} flexWrap={'wrap'}>
              <TextField
                id={"softwareVn"}
                variant="standard"
                label="Software Vn"
                {...register("softwareVn")}
              />

              <LMDatePicker
                value={getValues().dateOfActivation}
                required={true}
                label={'Aktivierungsdatum'}
                error={!!errors.dateOfActivation}
                errorHelperText={errors.dateOfActivation?.message}
                formRegisterObject={{...register('dateOfActivation',
                    { required: "Aktivierungsdatum wird benötigt"}
                  )}}
                onChange={value => setValue('dateOfActivation', value, { shouldDirty: true })}
              />
              <LMDatePicker
                value={getValues().licenceStart}
                required={true}
                label={'Lizenz gültig ab'}
                error={!!errors.licenceStart}
                errorHelperText={errors.licenceStart?.message}
                formRegisterObject={{...register('licenceStart',
                    { required: "Lizenz gültig ab wird benötigt"}
                  )}}
                onChange={value => setValue('licenceStart', value, { shouldDirty: true })}
              />
              <LMDatePicker
                value={getValues().licenceEnd}
                required={true}
                label={'Lizenz gültig bis'}
                error={!!errors.licenceEnd}
                errorHelperText={errors.licenceEnd?.message}
                formRegisterObject={{...register('licenceEnd',
                    { required: "Lizenz gültig bis wird benötigt"}
                  )}}
                onChange={value => setValue('licenceEnd', value, { shouldDirty: true })}
              />

              <Controller
                name={"soldToBiotronik"}
                control={control}
                render={({field: {onChange, value}}) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} {...register("soldToBiotronik")}/>}
                    label="Verkauft an Biotronik"
                  />
                )}
              />

              <FormControl id={'form_control_deviceType'} variant={'standard'}>
                <InputLabel id={'form_deviceType_label'}>Gerätetyp</InputLabel>
                <Select
                  id={"form_deviceType_selector"}
                  sx={{ width: "250px" }}
                  labelId={'form_deviceType_label'}
                  value={watchedDeviceType}
                  onChange={event => setValue('deviceType', event.target.value as string, {shouldDirty: true})}
                >
                  <MenuItem sx={{ height: "2rem"}} value={""}></MenuItem>
                  <MenuItem value={"hub"}>Hub</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <TextField sx={{width: '100%', mr: '2rem'}}
                       label={'Notizen'}
                       multiline size={'medium'} {...register("additionalNotes")} />
          </Grid>
        </Grid>

        {/* Right form element*/}
        <Grid container width={'50%'} display={'flex'} flexDirection={'column'} spacing={2}>
          <Box display={'grid'}>
            <Typography>Subgeräte</Typography>
            <DataGrid
              rows={subDevices}
              sx={{minHeight: '150px'}}
              columns={subDeviceColumns}
              getRowId={(row) => row.subDeviceNo}
              initialState={{
                pagination: {
                  paginationModel: {page: 0, pageSize: 5},
                },
              }}
              pageSizeOptions={[5, 10, 50]}
              rowSelectionModel={selectedSubDevice}
              onRowSelectionModelChange={handleSubDeviceSelectionChange}
            />
            <Stack spacing={2} direction={"row"}>
              <Button
                disabled={subDeviceButtonDisabled()}
                onClick={deleteSubDevice}
              >
                Löschen
              </Button>
              <SubDeviceDialogButton
                type="update"
                disabled={subDeviceButtonDisabled()}
                subDeviceDefaultValues={
                  selectedSubDevice.length === 1 ? findSelectedSubDevice() : getEmptySubDeviceForm()
                }
                submitOperation={updateSubDevice}
                updateDirty={updateDirty}
                validateSubDeviceId={validateSubDeviceId}
              />
              <SubDeviceDialogButton
                type="add"
                subDeviceDefaultValues={getEmptySubDeviceForm()}
                submitOperation={addSubDevice}
                updateDirty={updateDirty}
                validateSubDeviceId={validateSubDeviceId}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
