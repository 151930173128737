import React from 'react'
import PageTitle from "../components/subcomponents/PageTitle";
import {Alert, Box, Button, CircularProgress, Divider, Snackbar, Typography} from "@mui/material";
import {TablePaper} from "../components/TablePaper";
import {InnerPaperContainer} from "../components/subcomponents/Containers/Containers";
import BillingConfigForm, {BillingConfigFormValues} from "../components/forms/BillingConfigForm";
import PageFooter from "../components/subcomponents/Footer/PageFooter";
import ConfigService from '../services/ConfigService'
import ErrorExclamation from "../components/subcomponents/ErrorExclamation";
import {styled} from "@mui/material/styles";
import {FormIds} from "../constants";

const FooterPlaceholder = styled("span")({
  height: "3rem",
  display: "block"
})

export default function ConfigPage() {

  const [config, setConfig] = React.useState<any>(null)
  const [formDirty, setFormDirty] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)

  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState("")
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success")

  async function onBillingFormSubmit(billingFormData: BillingConfigFormValues) {
    const newConfig = ConfigService.mapBillingFormToConfig(billingFormData)

    if (!ConfigService.checkShareRevenues(newConfig, "implants")) {
      alert('Die Summe der Implantatumsatzbeteiligungen muss 100% ergeben')
      return
    }

    if (!ConfigService.checkShareRevenues(newConfig, "sensors")) {
      alert('Die Summe der Umsatzbeteiligungen der externen Sensoren muss 100% ergeben')
      return
    }

    try {
      await ConfigService.updateConfig(newConfig)
    } catch (e) {
      setShowSnackbar(true)
      setSnackbarSeverity("error")
      setSnackbarMessage("Fehler beim Aktualisieren der Config")
      return
    }

    setShowSnackbar(true)
    setSnackbarSeverity("success")
    setSnackbarMessage("Config erfolgreich aktualisiert")
  }

  function getDefaultBillingFormValues(): BillingConfigFormValues {
    const defaultValue = {
      semdatexFixedCosts: "0",
      getemedFixedCosts: "0",
      getemedHotlinePrice: "0",
      semdatexExternalSensorsRevenueShare: "0",
      getemedExternalSensorsRevenueShare: "0",
      biotronikExternalSensorsRevenueShare: "0",
      DocCirrusExternalSensorsRevenueShare: "0",
      semdatexImplantRevenueShare: "0",
      biotronikImplantRevenueShare: "0",
      DocCirrusImplantRevenueShare: "0"
    }

    return config === null ? defaultValue : ConfigService.mapConfigToBillingConfigForm(config)
  }

  async function loadData() {
    try {
      const config = await ConfigService.getConfig()
      setConfig(config)
    } catch (e) {
      console.error(e)
      setError(true)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    loadData().then()
  }, [])

  function renderForm() {
    return error ?
      <ErrorExclamation message="Could not fetch config information"/> :
      <BillingConfigForm onSubmit={onBillingFormSubmit} defaultValues={getDefaultBillingFormValues()}
                         updateDirty={setFormDirty}/>
  }

  return (
    <div className={"base-container"}>
      <PageTitle>Konfiguration</PageTitle>
      <Divider/>
      <TablePaper>
        <InnerPaperContainer>
          <Typography variant="h4">Abrechnung</Typography>
        </InnerPaperContainer>
        <Box sx={{padding: "1rem"}}>
          {
            loading ?
              <CircularProgress/> :
              renderForm()
          }
        </Box>
      </TablePaper>
      <FooterPlaceholder/>
      <PageFooter>
        <Button onClick={() => {
          window.location.reload()
        }} variant="contained" color="inherit">
          Abbrechen
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          form={FormIds.billingConfig}
          disabled={!formDirty}
        >
          Speichern
        </Button>
      </PageFooter>

      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity as "success" | "error"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}
