import Column from "./models/Column";
import {Product} from "./models/product";
import InPaceService from "./services/InPaceService";
import {GridColDef} from "@mui/x-data-grid";
import InCareNetHFService from "./services/InCareNetHFService";

export const MAX_TABLE_HEIGHT = 600

export const firstLevelSupport: string[] = [
  'Semdatex'
]

export const InCareNetTableColumns: Column[] = [
  {
    field: "customerName",
    headerName: "Kundenname",
    width: 200
  },
  {
    field: "customerNumber",
    headerName: "Kundennummer",
    width: 130
  },
  {
    field: "location",
    headerName: "Ort"
  },
  {
    field: "version",
    headerName: "Version",
    width: 80
  },
  {
    field: "externalSensors",
    headerName: "Ges. ext. Sensoren",
    width: 150,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "activeAbbott",
    headerName: "Abbott",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "activeBio",
    headerName: "Biotronik",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "activeBoston",
    headerName: "Boston",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "activeMedtronic",
    headerName: "Medtronic",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "activeMicroPort",
    headerName: "MicroPort",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "totalActiveImplants",
    headerName: "Ges. Implantate",
    width: 150,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "inactiveAbbott",
    headerName: "Abbott inaktiv",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "inactiveBio",
    headerName: "Bio inaktiv",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "inactiveBoston",
    headerName: "Boston inaktiv",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "inactiveMedtronic",
    headerName: "Medtronic inaktiv",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "inactiveMicroPort",
    headerName: "MicroPort inaktiv",
    width: 70,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "totalInactiveImplants",
    headerName: "Ges. Implantate",
    width: 150,
    warnIconIfError: InCareNetHFService.isDeviceCountValid,
  },
  {
    field: "implantConnectionRate",
    headerName: "Anschlussquote in %",
    width: 150,
  },
  {
    field: "url",
    headerName: "URL",
    width: 150
  },
  {
    field: "tmzActiveSince",
    headerName: "TMZ Aktiv seit",
    width: 130
  },
  {
    field: "priceSensorsPerPatient",
    headerName: "Extern Preis",
  },
  {
    field: "priceImplantsPerPatient",
    headerName: "Implantat Preis",
  },
  {
    field: "getemedHotlineSupport",
    headerName: "mit Getemed Telefon Support",
    iconFromBool: true,
    width: 200
  },
  {
    field: "os",
    headerName: "OS",
  },
]

export const InPaceTableColumns: Column[] = [
  {
    field: "customerName",
    headerName: "Kundenname",
    width: 200
  },
  {
    field: "customerNumber",
    headerName: "Kundennummer",
    width: 120
  },
  {
    field: "location",
    headerName: "Ort",
  },
  {
    field: "serialNr",
    headerName: "Seriennummer",
    width: 180
  },
  {
    field: "softwareVn",
    headerName: "Software Vn",
  },
  {
    field: "manufacturer",
    headerName: "Hersteller",
  },
  {
    field: "deviceName",
    headerName: "Gerätename",
  },
  {
    field: "licenceStart",
    headerName: "Lizenz gültig ab",
    width: 120
  },
  {
    field: "licenceEnd",
    headerName: "Lizenz gültig bis",
    width: 120
  },
  {
    field: "dateOfActivation",
    headerName: "Aktivierungsdatum",
    width: 130
  },
  {
    field: "licenceValid",
    headerName: "Gültigkeit",
    width: 80,
  },
  {
    field: "additionalNotes",
    headerName: "Notiz",
    width: 200
  },
  {
    field: "soldToBiotronik",
    headerName: "Verkauft an Bio",
    width: 120
  },
  {
    field: "deviceType",
    headerName: "Gerätetyp",
  },
  {
    field: "numberOfSubdevices",
    headerName: "Subgeräte Anzahl",
    width: 140
  },
]

export const subDeviceColumns: GridColDef[] = [
  {
    field: "subDeviceNo",
    headerName: "ID",
    flex: 20,
  },
  {
    field: "einstellung",
    headerName: "Einstellung",
    flex: 20,
  },
  {
    field: "subDeviceSoftwareVn",
    headerName: "Software Vn",
    flex: 20,
  }
]

export const CustomerTableColumns: Column[] = [
  {
    field: "name",
    headerName: "Kundenname",
    width: 200
  },
  {
    field: "doctorName",
    headerName: "Arztname",
    width: 150
  },
  {
    field: "customerNumber",
    headerName: "Kundennr.",
  },
  {
    field: "postalCode",
    headerName: "PLZ",
    width: 70
  },
  {
    field: "location",
    headerName: "Ort",
  },
  {
    field: "street",
    headerName: "Straße",
    width: 150
  },
  {
    field: "system",
    headerName: "System",
    width: 150
  },
  {
    field: "biotronikClientName",
    headerName: "Bio. Kundenname",
    width: 200
  },
  {
    field: "biotronikCustomerNumber",
    headerName: "Bio. Kundennr.",
  },
  {
    field: "eTin",
    headerName: "Bio. eTin",
  },
  {
    field: "biotronikImplantPrice",
    headerName: "Bio. Implantat Preis (€)",
    width: 160
  },
  {
    field: "biotronikExternalSensorsPrice",
    headerName: "Bio. ext. Sensoren Preis (€)",
    width: 160
  },
  {
    field: "bostonSAPID",
    headerName: "Boston SAP ID",
    width: 120
  },
]

export const Products: Product[] = [
  {
    name: "inCareNetHF",
    label: "INCARENET HF",
    getTableItems: InCareNetHFService.getTableInCareNetHFData,
    getData: InCareNetHFService.getInCareNetHFs,
    deleteItem: InCareNetHFService.deleteInCareNetHF,
    tableColumns: InCareNetTableColumns,
  },
  {
    name: "inPace",
    label: "INPACE",
    getTableItems: InPaceService.getTableDeviceData,
    getData: InPaceService.getInPaceDevices,
    deleteItem: InPaceService.deleteDevice,
    tableColumns: InPaceTableColumns,
  },
]

export const FormIds = {
  billingConfig: "billing-config-form",
  updateCustomer: "update-customer-form",
  updateInCareNetHF: "add-icnhf-form",
  updateInPace: "add-inpace-form",
  subDevice: "subdevice-form"
}

export const inActiveFieldNames = [
  "inactiveAbbott",
  "inactiveBio",
  "inactiveBoston",
  "inactiveMedtronic",
  "inactiveMicroPort",
  "totalInactiveImplants",
]
export const activeFieldNames = [
  "activeAbbott",
  "activeBio",
  "activeBoston",
  "activeMedtronic",
  "activeMicroPort",
  "externalSensors",
  "totalActiveImplants"
]

export const serverDomain = process.env.REACT_APP_SERVER_DOMAIN ? `https://${process.env.REACT_APP_SERVER_DOMAIN}` : 'http://localhost:3001'
