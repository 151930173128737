import React, {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Stack, TextField, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {FormIds} from "../../constants";
import InputAdornment from "@mui/material/InputAdornment";

const SectionHeaderContainer = styled("div")({
  width: "100%",
  textAlign: "left",
  paddingTop: "2rem",
  paddingBottom: ".5rem"
})

export interface BillingConfigFormValues {
  semdatexFixedCosts: string
  getemedFixedCosts: string
  getemedHotlinePrice: string
  semdatexImplantRevenueShare: string
  biotronikImplantRevenueShare: string
  DocCirrusImplantRevenueShare: string
  semdatexExternalSensorsRevenueShare: string
  getemedExternalSensorsRevenueShare: string
  biotronikExternalSensorsRevenueShare: string
  DocCirrusExternalSensorsRevenueShare: string
}

interface BillingConfigFormProps {
  onSubmit: (data: BillingConfigFormValues) => void
  /** This function updates the dirty state in the parents component */
  updateDirty: (dirty: boolean) => void
  defaultValues: BillingConfigFormValues
}

export default function BillingConfigForm({onSubmit, defaultValues, updateDirty}: Readonly<BillingConfigFormProps>) {

  const form = useForm<BillingConfigFormValues>({
    defaultValues
  })

  const {register, handleSubmit, formState} = form

  const {errors} = formState

  useEffect(() => {
    updateDirty(form.formState.isDirty)
  }, [form.formState.isDirty])

  return (
    <form id={FormIds.billingConfig} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={3}>
        <Stack spacing={2}>
          <SectionHeaderContainer>
            <Typography variant="h5">Fixkosten</Typography>
          </SectionHeaderContainer>
          <Stack direction="row"
                 spacing={5}
                 justifyItems={'stretch'}
          >
            <TextField
              sx={{width: '17rem'}}
              id={"semdatexFixedCosts"}
              variant="outlined"
              label="SEMDATEX Fixkosten pro Patient"
              type="number"
              required
              {...register("semdatexFixedCosts", {
                required: "SEMDATEX Fixkosten werden benötigt"
              })}
              error={!!errors.semdatexFixedCosts}
              helperText={errors.semdatexFixedCosts?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>€</InputAdornment>}}
            />
            <TextField
              sx={{width: '17rem'}}
              id={"getemedFixedCosts"}
              variant="outlined"
              label="Getemed Fixkosten pro Patient"
              type="number"
              required
              {...register("getemedFixedCosts", {
                required: "Getemed Fixkosten werden benötigt"
              })}
              error={!!errors.getemedFixedCosts}
              helperText={errors.getemedFixedCosts?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>€</InputAdornment>}}
            />
            <TextField
              sx={{width: '17rem'}}
              id={"getemedHotlinePrice"}
              variant="outlined"
              label="mit Getemed Telefonsupport pro Patient"
              type="number"
              required
              {...register("getemedHotlinePrice", {
                required: "Getemed Telefonsupport werden benötigt"
              })}
              error={!!errors.getemedHotlinePrice}
              helperText={errors.getemedHotlinePrice?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>€</InputAdornment>}}
            />
          </Stack>
        </Stack>
        <Stack spacing={2}>
          <SectionHeaderContainer>
            <Typography variant="h5">Externe Sensoren Shares</Typography>
          </SectionHeaderContainer>
          <Stack
            direction="row"
            spacing={5}
          >
            <TextField
              id={"semdatexExternalSensorsRevenueShare"}
              variant="outlined"
              label="SEMDATEX Share"
              type="number"
              required
              {...register("semdatexExternalSensorsRevenueShare", {
                required: "SEMDATEX Anteil werden benötigt"
              })}
              error={!!errors.semdatexExternalSensorsRevenueShare}
              helperText={errors.semdatexExternalSensorsRevenueShare?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
            />
            <TextField
              id={"getemedExternalSensorsRevenueShare"}
              variant="outlined"
              label="Getemed Share"
              type="number"
              required
              {...register("getemedExternalSensorsRevenueShare", {
                required: "Getemed Anteil werden benötigt"
              })}
              error={!!errors.getemedExternalSensorsRevenueShare}
              helperText={errors.getemedExternalSensorsRevenueShare?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
            />
            <TextField
              id={"biotronikExternalSensorsRevenueShare"}
              variant="outlined"
              label="Biotronik Share"
              type="number"
              required
              {...register("biotronikExternalSensorsRevenueShare", {
                required: "Biotronik Anteil werden benötigt"
              })}
              error={!!errors.biotronikExternalSensorsRevenueShare}
              helperText={errors.biotronikExternalSensorsRevenueShare?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
            />
            <TextField
              id={"DocCirrusExternalSensorsRevenueShare"}
              variant="outlined"
              label="DocCirrus Share"
              type="number"
              required
              {...register("DocCirrusExternalSensorsRevenueShare", {
                required: "DocCirrus Anteil werden benötigt"
              })}
              error={!!errors.DocCirrusExternalSensorsRevenueShare}
              helperText={errors.DocCirrusExternalSensorsRevenueShare?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
            />
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <SectionHeaderContainer>
            <Typography variant="h5">Implantate Shares</Typography>
          </SectionHeaderContainer>
          <Stack
            direction="row"
            spacing={5}
          >
            <TextField
              id={"semdatexImplantRevenueShare"}
              variant="outlined"
              label="SEMDATEX Share"
              type="number"
              required
              {...register("semdatexImplantRevenueShare", {
                required: "SEMDATEX Anteil werden benötigt"
              })}
              error={!!errors.semdatexImplantRevenueShare}
              helperText={errors.semdatexImplantRevenueShare?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
            />
            <TextField
              id={"biotronikImplantRevenueShare"}
              variant="outlined"
              label="Biotronik Share"
              type="number"
              required
              {...register("biotronikImplantRevenueShare", {
                required: "Biotronik Anteil werden benötigt"
              })}
              error={!!errors.biotronikImplantRevenueShare}
              helperText={errors.biotronikImplantRevenueShare?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
            />
            <TextField
              id={"DocCirrusImplantRevenueShare"}
              variant="outlined"
              label="DocCirrus Share"
              type="number"
              required
              {...register("DocCirrusImplantRevenueShare", {
                required: "DocCirrus Anteil werden benötigt"
              })}
              error={!!errors.DocCirrusImplantRevenueShare}
              helperText={errors.DocCirrusImplantRevenueShare?.message}
              InputProps={{endAdornment: <InputAdornment position={'end'}>%</InputAdornment>}}
            />
          </Stack>
        </Stack>
      </Stack>
    </form>
  )
}
