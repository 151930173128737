import KeycloakFunctions from "../keycloakFunctions";
import {serverDomain} from "../constants";
import {Device} from "../models/device";
import {getGETUrl} from "../utils/helperFunctions";

/**
 * Gets all devices
 *
 * @param query
 */
export async function getDevices(query?: any): Promise<Device[]>{
  let response;
  const token = KeycloakFunctions.getToken()

  const url = getGETUrl("devices", query)

  try {
    response = await fetch(url, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    return await response.json()
  } catch (e) {
    throw new Error('Error while fetching devices from server')
  }
}

/**
 * Adds a device to our DB
 *
 * @param deviceObject
 */
export async function addDevice(deviceObject: Device){
  const token = KeycloakFunctions.getToken()
  const response = await fetch(`${serverDomain}/devices`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(deviceObject)
  })

  if (!response.ok) {
    throw new Error(`Could not save Device Object! HTTP status: ${response.status}`);
  }

}

/**
 *
 * Updates a device in our DB
 *
 * @param {Object} deviceObject
 * @param {Object} deviceObject.device - The device object.
 * @param {string} deviceObject.user - The user who has done the action.
 * @return {Promise<void>}
 */
export async function updateDevice(deviceObject: Device): Promise<void>{
  const token = KeycloakFunctions.getToken()
  const response = await fetch(`${serverDomain}/devices`, {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(deviceObject)
  })

  if (!response.ok) {
    throw new Error(`Could not save Device Object! HTTP status: ${response.status}`);
  }
}

/**
 * Deletes a device in our DB
 *
 * @param deviceId
 */
export async function deleteDevice(deviceId: string) {
  const token = KeycloakFunctions.getToken()

  try {
    const response = await fetch(`${serverDomain}/devices/${deviceId}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${token}`
      },
    })

    if (!response.ok) {
      throw new Error()
    }
  } catch (e) {
    throw new Error('Error while deleting a device')
  }
}
