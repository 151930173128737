import KeycloakFunctions from "../keycloakFunctions";
import {serverDomain} from "../constants";
import {Customer} from "../models/customer";


/**
 * Checks if a customer already exists in our DB by
 * looking for duplicated in customerName and customerNumber
 *
 * @param customer
 */
export async function doesCustomerAlreadyExist(customer: Customer) {
  const token = KeycloakFunctions.getToken()
  const response = await fetch(
    `${serverDomain}/customers/exists?name=${customer.name}&customerNumber=${customer.customerNumber}`, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${token}`
    },
  })

  if (!response.ok) {
    throw new Error(`Could not check if customer exists! HTTP status: ${response.status}`);
  }

  return await response.json()
}

/**
 * Gets all customers
 */
export async function getCustomers() {
  let response;
  const token = KeycloakFunctions.getToken()
  try {
    response = await fetch(`${serverDomain}/customers`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    return await response.json()
  } catch (e) {
    throw new Error('Error while fetching customers from server')
  }
}

/**
 * Gets selected customers by their customerID
 *
 * @param id
 */
export async function getCustomerById(id: string) {
  let response;
  const token = KeycloakFunctions.getToken()
  try {
    response = await fetch(`${serverDomain}/customers/${id}`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    return await response.json()
  } catch (e) {
    throw new Error('Error while fetching customers from server')
  }
}

/**
 * Adds a customer to our DB
 *
 * @param customerObject
 */
export async function addCustomer(customerObject: Customer) {
  const token = KeycloakFunctions.getToken()
  const response = await fetch(`${serverDomain}/customers`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(customerObject)
  })

  if (!response.ok) {
    throw new Error(`Could not add customer! HTTP status: ${response.status}`);
  }
}

/**
 * Updates a customer in our DB
 *
 * @param {Object} customerObject
 * @param {Object} customerObject.device - The customer object.
 * @param {string} customerObject.user - The user who has done the action.
 *
 * @return {Promise<void>}
 */
export async function updateCustomer(customerObject: Customer): Promise<void> {
  const token = KeycloakFunctions.getToken()
  const response = await fetch(`${serverDomain}/customers`, {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(customerObject)
  })

  if (!response.ok) {
    throw new Error(`Could not update customer! HTTP status: ${response.status}`);
  }
}

/**
 * (Safe-) Deletes a customer in our DB
 *
 * Safe deleting is used to keep the customer in our DB for 30 more days after officially pressing delete,
 * in case the user wants to restore the data.
 *
 * @param customerId
 * @param safe
 */
export async function deleteCustomer(customerId: string, safe = true) {
  const token = KeycloakFunctions.getToken()
  const url = safe ? `${serverDomain}/customers/saveDelete/${customerId}` : `${serverDomain}/customers/${customerId}`

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
  })

  if (!response.ok) {
    throw new Error(`Could not ${safe ? "safely" : ""} delete customer! HTTP status: ${response.status}`);
  }
}

