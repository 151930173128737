import {excelDesigns, tableHeaderConstants} from "./ExcelConstants";

/**
 * This method will create the headers for our tables.
 * They will correspond as categories for our tables.
 *
 * @param sheet the used worksheet
 */
export function createExcelHeader(sheet) {
  const headers = [
    {range: "A1:I1", value: "Mengengerüst", style: excelDesigns.lightYellowBackground},
    {range: "J1:M1", value: "BIO-Verkaufspreise je TMZ abgl. Rabatt", style: excelDesigns.lightYellowBackground},
    {range: "O1:AM1", value: "Externe Sensoren", style: excelDesigns.redBackground},
    {range: "AO1:AZ1", value: "Implantate", style: excelDesigns.redBackground},
    {range: "BB1:BH1", value: "Abrechnung der Implantate für die Vergangenheit", style: excelDesigns.redBackground}
  ];

  headers.forEach(header => {
    sheet.mergeCells(header.range);
    sheet.getCell(header.range.split(':')[0]).value = header.value;
    sheet.getCell(header.range.split(':')[0]).style = header.style;
  });

  sheet.getRow(1).height = sheet.getRow(2).height = 40;
}

export function colorColumn(address, sheet, style) {
  const column = sheet.getColumn(sheet.getCell(address).col);
  column.eachCell({includeEmpty: true}, (cell, rowNumber) => {
    if (rowNumber >= 2) {
      cell.style = style;
    }
  });
}

export function setDesigns(sheet) {
  const columnsToColor = [
    {address: "J2", style: excelDesigns.darkBrownBackground},
    {address: "K2", style: excelDesigns.darkBrownBackground},
    {address: "L2", style: excelDesigns.darkBrownBackground},
    {address: "M2", style: excelDesigns.darkBrownBackground},
    {address: "AE2", style: excelDesigns.darkGreenBackground},
    {address: "AF2", style: excelDesigns.darkGreenBackground},
    {address: "AD2", style: excelDesigns.blueBackground},
    {address: "T2", style: excelDesigns.blueBackground},
    {address: "U2", style: excelDesigns.blueBackground},
    {address: "AG2", style: excelDesigns.lightGreenBackground},
    {address: "AH2", style: excelDesigns.lightGreenBackground},
    {address: "AI2", style: excelDesigns.lightGreenBackground},
    {address: "AJ2", style: excelDesigns.lightGreenBackground},
    {address: "AK2", style: excelDesigns.lightGreenBackground},
    {address: "AL2", style: excelDesigns.lightBrownBackground},
    {address: "AM2", style: excelDesigns.lightBrownBackground},
    {address: "V3", style: excelDesigns.lightGreenBackground},
    {address: "W3", style: excelDesigns.lightGreenBackground},
    {address: "X3", style: excelDesigns.darkGreenBackground},
    {address: "Y3", style: excelDesigns.darkGreenBackground},
    {address: "Z3", style: excelDesigns.darkBrownBackground},
    {address: "AA3", style: excelDesigns.darkBrownBackground},
    {address: "AB3", style: excelDesigns.lightBrownBackground},
    {address: "AC3", style: excelDesigns.lightBrownBackground},
    {address: "S2", style: excelDesigns.blueBackground},
    {address: "O2", style: excelDesigns.darkGreenBackground},
    {address: "P2", style: excelDesigns.darkGreenBackground},
    {address: "Q2", style: excelDesigns.darkGreenBackground},
    {address: "R2", style: excelDesigns.darkGreenBackground},
    {address: "AO2", style: excelDesigns.lightGreenBackground},
    {address: "AP2", style: excelDesigns.blueBackground},
    {address: "AQ2", style: excelDesigns.blueBackground},
    {address: "AR2", style: excelDesigns.blueBackground},
    {address: "AS2", style: excelDesigns.blueBackground},
    {address: "AT2", style: excelDesigns.lightYellowBackground},
    {address: "AU2", style: excelDesigns.lightYellowBackground},
    {address: "AV2", style: excelDesigns.lightYellowBackground},
    {address: "AW2", style: excelDesigns.lightYellowBackground},
    {address: "AX2", style: excelDesigns.lightYellowBackground},
    {address: "AY2", style: excelDesigns.lightYellowBackground},
    {address: "AZ2", style: excelDesigns.blueBackground},
    {address: "AT2", style: excelDesigns.lightGreenBackground},
    {address: "AU2", style: excelDesigns.lightGreenBackground},
    {address: "AV2", style: excelDesigns.darkBrownBackground},
    {address: "AW2", style: excelDesigns.darkBrownBackground},
    {address: "AX2", style: excelDesigns.lightBrownBackground},
    {address: "AY2", style: excelDesigns.lightBrownBackground},
    {address: "BB2", style: excelDesigns.darkGreenBackground},
    {address: "BC2", style: excelDesigns.darkGreenBackground},
    {address: "BD2", style: excelDesigns.lightGreenBackground},
    {address: "BE2", style: excelDesigns.lightGreenBackground},
    {address: "BF2", style: excelDesigns.darkGreenBackground},
    {address: "BG2", style: excelDesigns.lightBrownBackground},
    {address: "BH2", style: excelDesigns.lightBrownBackground}
  ];

  columnsToColor.forEach(({address, style}) => colorColumn(address, sheet, style));

  sheet.getCell("V2").style = excelDesigns.lightYellowBackground;
  sheet.getCell("AT2").style = excelDesigns.lightYellowBackground;

  // Borders
  const borderColumns = [
    "J2", "K2", "L2", "M2", "O2", "P2", "Q2", "R2", "S2", "T2", "U2", "V2", "W2", "X2", "Y2", "Z2", "AA2", "AB2", "AC2",
    "AL2", "AM2", "AD2", "AE2", "AO2", "AP2", "AQ2", "AR2", "AS2", "AT2", "AU2", "AV2", "AW2", "AX2", "AY2", "AZ2",
    "AF4", "AG4", "AH4", "AI4", "AJ4", "AK4", "BB4", "BC4", "BD4", "BE4", "BF4", "BG4", "BH4"
  ];


  borderColumns.forEach(address => colorTable(sheet.getColumn(sheet.getCell(address).col)));

  sheet.getCell("AI3").style = {...sheet.getCell("AI3").style, border: excelDesigns.tableBorder.border};
  sheet.getCell("Q3").style = {...sheet.getCell("Q3").style, border: excelDesigns.tableBorder.border};

  const oddColumns = ["A5", "B5", "C5", "D5", "E5", "F5", "G5", "H5", 'I5'];
  oddColumns.forEach(address => colorOdd(sheet.getColumn(sheet.getCell(address).col)));

  function colorTable(column) {
    column.eachCell({includeEmpty: true}, (cell, rowNumber) => {
      cell.style = {
        ...cell.style,
        border: excelDesigns.tableBorderLeftRight.border
      };

      if (rowNumber === 2 || rowNumber === 4) {
        cell.style = {
          ...cell.style,
          border: excelDesigns.tableBorder.border
        };
      }
    });
  }

  function colorOdd(column) {
    column.eachCell({includeEmpty: true}, (cell, rowNumber) => {
      if (rowNumber >= 5 && rowNumber % 2 !== 0) {
        cell.style = {...cell.style, fill: excelDesigns.oddBackground.fill};
      }
    });
  }
}

/**
 * This method will create our *table* headers
 * which will have the corresponding values beneath them
 *
 * @param sheet the used worksheet
 */
export function createExcelTables(sheet) {
  const mergedCells = [
    "O2:R2", "T2:U2", "V2:AC2", "AE2:AF2", "AG2:AK2", "AL2:AM2", "AP2:AQ2", "AT2:AY2", "AR2:AS2", "BB2:BC2", "BD2:BE2", "BG2:BH2"
  ];


  mergedCells.forEach(range => sheet.mergeCells(range));

  tableHeaderConstants.forEach((string, index) => {
    sheet.getColumn(index + 1).eachCell({includeEmpty: true}, cell => {
      cell.style = {
        ...cell.style,
        alignment: {
          shrinkToFit: true,
          wrapText: true,
          horizontal: "center",
          vertical: "middle",
        },
        protection: {
          locked: true
        }
      };

      if (cell.row !== 1) { //To avoid replacing our headers
        cell.value = string;
      }
    });
  });
}

/**
 * Creates the footer of the Excel sheet, containing the sums of columns
 *
 * @param sheet the used worksheet
 * @param solSize size of sol DB
 */
export function createFooter(sheet, solSize) {
  if (solSize === 0) return;

  const rowNumber = 5 + solSize + 1;

  sheet.addRow({});
  sheet.addRow({});

  sheet.getCell(`A${rowNumber}`).value = 'Summe:';

  const externalSenSum = `SUM(G5:G${rowNumber - 2})`;
  const externalImplantsSum = `SUM(H5:H${rowNumber - 2})`;

  sheet.columns.forEach(column => {
    if (isCorrectColumn(column.letter)) {
      column.eachCell({includeEmpty: true}, (cell, rN) => {
        if (rN === rowNumber) {
          switch (column.letter) {
            case 'J':
              cell.value = {formula: `SUM(L5:L${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'K':
              cell.value = {formula: `SUM(M5:M${rowNumber - 2})/${externalImplantsSum}`};
              break;
            case 'O':
              cell.value = {formula: `SUM(P5:P${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'T':
              cell.value = {formula: `SUM(U5:U${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'V':
              cell.value = {formula: `SUM(W5:W${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'X':
              cell.value = {formula: `SUM(Y5:Y${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'Z':
              cell.value = {formula: `SUM(AA5:AA${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'AB':
              cell.value = {formula: `SUM(AC5:AC${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'AE':
              cell.value = {formula: `SUM(AF5:AF${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'AI':
              cell.value = {formula: `SUM(AJ5:AJ${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'AL':
              cell.value = {formula: `SUM(AM5:AM${rowNumber - 2})/${externalSenSum}`};
              break;
            case 'AP':
              cell.value = {formula: `SUM(AQ5:AQ${rowNumber - 2})/${externalImplantsSum}`};
              break;
            case 'AR':
              cell.value = {formula: `SUM(AS5:AS${rowNumber - 2})/${externalImplantsSum}`};
              break;
            case 'AT':
              cell.value = {formula: `SUM(AU5:AU${rowNumber - 2})/${externalImplantsSum}`};
              break;
            case 'AV':
              cell.value = {formula: `SUM(AW5:AW${rowNumber - 2})/${externalImplantsSum}`};
              break;
            case 'AX':
              cell.value = {formula: `SUM(AY5:AY${rowNumber - 2})/${externalImplantsSum}`};
              break;
            case 'BD':
              cell.value = {formula: `SUM(BE5:BE${rowNumber - 2})/${externalImplantsSum}`};
              break;
            case 'BG':
              cell.value = {formula: `SUM(BH5:BH${rowNumber - 2})/${externalImplantsSum}`};
              break;
            default:
              if (column.letter !== 'AP') {
                cell.value = {formula: `SUM(${column.letter}5:${column.letter}${rowNumber - 2})`};
              }
              break;
          }
        }
      });
    }
  });

  function isCorrectColumn(letter) {
    switch (letter) {
      case 'A':
      case 'B':
      case 'C':
      case 'D':
      case 'E':
      case 'F':
      case 'I':
      case 'N':
      case 'AN':
      case 'BA':
        return false;
      default:
        return true;
    }
  }
}
