import myzod, {Infer} from "myzod";
import isURL from 'validator/lib/isURL'

export const currentDeviceCountSchema = myzod.object({
  deviceCountActive: myzod.object({
    Bio: myzod.number().min(0),
    Abbott: myzod.number().min(0),
    Boston: myzod.number().min(0),
    Medtronic: myzod.number().min(0),
    MicroPort: myzod.number().min(0),
    ExternalSensors: myzod.number().min(0),
  }),
  deviceCountInactive: myzod.object({
    Bio: myzod.number().min(0),
    Abbott: myzod.number().min(0),
    Boston: myzod.number().min(0),
    Medtronic: myzod.number().min(0),
    MicroPort: myzod.number().min(0),
    ExternalSensors: myzod.number().min(0),
  }),
})

export const inCareNetHFSchema = myzod.object({
  _id: myzod.string().optional(),
  version: myzod.string().optional(),
  os: myzod.string(),
  solName: myzod.literal('incardio-dashboard'),
  customerId: myzod.string(),
  url: myzod.string().withPredicate(isURL, 'URL is not valid'),
  getemedHotlineSupport: myzod.boolean(),
  token: myzod.string().optional().required(),
  system: myzod.string().optional(),
  priceSensorsPerPatient: myzod.number().min(0),
  priceImplantsPerPatient: myzod.number().min(0),
  currentDeviceCount: currentDeviceCountSchema.optional(), // only for getting
  discount: myzod.number().min(0),
  tmzActiveSince: myzod.string(),
})

export type InCareNetHF = Infer<typeof inCareNetHFSchema>


export interface InCareNetHFTableData {
  _id: string
  customerId: string // Necessary to get customer related data
  customerName: string
  version: string
  customerNumber: string
  location: string
  getemedHotlineSupport: Element
  activeAbbott: number
  activeBio: number
  activeBoston: number
  activeMedtronic: number
  activeMicroPort: number
  externalSensors: number
  totalActiveImplants: number
  inactiveAbbott: number
  inactiveBio: number
  inactiveBoston: number
  inactiveMedtronic: number
  inactiveMicroPort: number
  totalInactiveImplants: number
  implantConnectionRate: number
  url: string
  tmzActiveSince: string
  priceImplantsPerPatient: number
  priceSensorsPerPatient: number
  os: string
  [key: string]: any; // Index signature
}
