import {BillingConfigFormValues} from "../components/forms/BillingConfigForm";
import {Config, configSchema} from "../models/Config";
import {getConfig, updateConfig} from "../api/config";
import myzod from "myzod";

export default class ConfigService {

  static async getConfig() {
    const config = await getConfig()

    if (typeof config !== 'object') {
      throw new Error("Fetched config is not an object")
    }

    return config as Config
  }

  static async updateConfig(config: Config) {
    const result = configSchema.try(config)

    if (result instanceof myzod.ValidationError) {
      console.error('Validation Error, it appears the given object is not a config')
    } else {
      await updateConfig(config)
    }
  }

  static mapConfigToBillingConfigForm(config: any): BillingConfigFormValues {
    return {
      semdatexFixedCosts: config.semdatexFixedCosts,
      getemedFixedCosts: config.getemedFixedCosts,
      getemedHotlinePrice: config.getemedHotlinePrice,
      semdatexImplantRevenueShare: config.implantRevenueShare.semdatex,
      biotronikImplantRevenueShare: config.implantRevenueShare.biotronik,
      DocCirrusImplantRevenueShare: config.implantRevenueShare.docCirrus,
      semdatexExternalSensorsRevenueShare: config.revenueShare.semdatex,
      getemedExternalSensorsRevenueShare: config.revenueShare.getemed,
      biotronikExternalSensorsRevenueShare: config.revenueShare.biotronik,
      DocCirrusExternalSensorsRevenueShare: config.revenueShare.docCirrus
    }
  }

  static mapBillingFormToConfig(billingFormData: BillingConfigFormValues): Config {
    return {
      semdatexFixedCosts: parseFloat(billingFormData.semdatexFixedCosts),
      getemedFixedCosts: parseFloat(billingFormData.getemedFixedCosts),
      getemedHotlinePrice: parseFloat(billingFormData.getemedHotlinePrice),
      implantRevenueShare: {
        semdatex: parseFloat(billingFormData.semdatexImplantRevenueShare),
        biotronik: parseFloat(billingFormData.biotronikImplantRevenueShare),
        docCirrus: parseFloat(billingFormData.DocCirrusImplantRevenueShare)
      },
      revenueShare: {
        semdatex: parseFloat(billingFormData.semdatexExternalSensorsRevenueShare),
        getemed: parseFloat(billingFormData.getemedExternalSensorsRevenueShare),
        biotronik: parseFloat(billingFormData.biotronikExternalSensorsRevenueShare),
        docCirrus: parseFloat(billingFormData.DocCirrusExternalSensorsRevenueShare)
      }
    }
  }

  /**
   * Checks if the revenue shares of either implants or sensors are valid. The sum must be 100 (percent).
   * @param config Config object which should be checked
   * @param subject Either "implants" or "sensors" depending on which revenue shares should be checked
   */
  static checkShareRevenues(config: Config, subject: "implants" | "sensors") {
    return subject === "implants" ?
      config.implantRevenueShare.biotronik + config.implantRevenueShare.docCirrus + config.implantRevenueShare.semdatex === 100 :
      config.revenueShare.semdatex + config.revenueShare.getemed + config.revenueShare.biotronik + config.revenueShare.docCirrus === 100
  }
}
