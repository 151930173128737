/**
 * -----------------------------------------------------------------------------------------------------------
 * | This file always has the corresponding DB that it will look through as a parameter.                     |
 * | We do this to prevent getting the DB x times.                                                           |
 * | Correct usage would be to get DB beforehand and pass the *response's data* in as the parameter.         |
 * -----------------------------------------------------------------------------------------------------------
 */
import {getICNDetails} from "../api/software";
import InCareNetHFService from "../services/InCareNetHFService";
import CustomerService from "../services/CustomerService";

export const formulaPrefix = "$$FORMULA$$" //used to identify formulas later on

/**
 * Returns a customers eTin from DB by his customerNumber
 *
 * @param customerNumber
 * @param customers a list/array containing all customers
 * @returns {string} eTin if found, if not returns empty string
 */
function getTin(customerNumber, customers) {
  let tin = ""

  for (const customer of customers) {
    if (customer.customerNumber === customerNumber) {
      tin = customer.eTin
      break
    }
  }

  return tin
}

/**
 *
 * @param sols SOL db
 * @param customers customer db
 * @param persistentState /configuration
 * @returns {Promise<*[]>} array of Objects containing all necessary billing information
 */
export async function getExcelInfo(sols, customers, persistentState) {
  const excelInfo = []

  for (const sol of sols) {
    const index = sols.indexOf(sol);
    const details = await getICNDetails(sol._id)
    const customer = await CustomerService.getCustomerById(sol.customerId)

    if (!customer.exportCustomer) {
      console.log(`Customer ${customer.name} is not exported, skipping...`)
      continue
    }

    const currentRow = index + 5
    const obj = {
      biotronikCustomerNumber: customer.biotronikCustomerNumber,
      customerName: sol.customerName,
      tmzActiveSince: details.tmzActiveSince,
      customerSystem: customer.system,
      customerNumber: sol.customerNumber,
      eTin: getTin(sol.customerNumber, customers),
      externalSensors: sol.currentDeviceCount.deviceCountActive.ExternalSensors,
      totalActiveImplants: InCareNetHFService.getSumOfDeviceCount(sol, true),
      discount: details.discount,
      externalSensorsPrice: details.priceSensorsPerPatient,
      totalActiveImplantsPrice: details.priceImplantsPerPatient,
      externalSensorsSum: `${formulaPrefix}J${currentRow}*G${currentRow}`, // externalSensorsPrice * externalSensors
      totalActiveImplantsSum: `${formulaPrefix}K${currentRow}*H${currentRow} `, // activeImplantsPrice * activeImplants
      getemedCostsPerPiece: persistentState.getemedFixedCosts,
      getemedTotalCost: `${formulaPrefix}O${currentRow}*G${currentRow}`, //getemedFixCosts * externalSensors
      getemedHotlinePerPiece: details.getemedHotlineSupport ? persistentState.getemedHotlinePrice : 0,
      getemedHotlineTotal: details.getemedHotlineSupport ? `${formulaPrefix}P${currentRow}*G${currentRow}` : 0, //hotlineCostPerPiece * externalSensors
      biotronikExternalSensorsPriceEach: ``,//customer.biotronikExternalSensorsPrice,
      marge: `${formulaPrefix}J${currentRow}-O${currentRow}`,// `ABS(${formulaPrefix}R${currentRow}-N${currentRow})`, // biotronikExternalSensorsPriceEach - getemedHotlineFixCosts
      margeTotal: `${formulaPrefix}T${currentRow}*G${currentRow}`, // marge * externalSensors
      margeDistributionSemdatex: `${formulaPrefix}T${currentRow}*(${persistentState.revenueShare.semdatex}/100)`,
      margeDistributionSemdatexTotal: `${formulaPrefix}V${currentRow}*G${currentRow}`,
      margeDistributionGetemed: `${formulaPrefix}T${currentRow}*(${persistentState.revenueShare.getemed}/100)`,
      margeDistributionGetemedTotal: `${formulaPrefix}X${currentRow}*G${currentRow}`,
      margeDistributionBiotronik: `${formulaPrefix}T${currentRow}*(${persistentState.revenueShare.biotronik}/100)`,
      margeDistributionBiotronikTotal: `${formulaPrefix}Z${currentRow}*G${currentRow}`,
      margeDistributionDocCirrus: `${formulaPrefix}T${currentRow}*(${persistentState.revenueShare.docCirrus}/100)`,
      margeDistributionDocCirrusTotal: `${formulaPrefix}AB${currentRow}*G${currentRow}`,
      margeAfterDistribution: `${formulaPrefix}ROUND(V${currentRow}+X${currentRow}+Z${currentRow}+AB${currentRow},2)`,
      getemedBillPerPiece: `${formulaPrefix}O${currentRow}+Q${currentRow}+X${currentRow}`,
      getemedBillAmount: `${formulaPrefix}+AE${currentRow}*G${currentRow}`,
      alt1: "",//Empty? TODO REMOVE
      alt2: "",//Empty? TODO REMOVE
      nachberechnungPrice: `${formulaPrefix}+O${currentRow}+V${currentRow}+X${currentRow}+AB${currentRow}+Q${currentRow}`,
      nachberechnungAmount: `${formulaPrefix}+AI${currentRow}*G${currentRow}`,
      nachberechnungDifference: ``, //Empty?
      docCirrusSharePricePerPiece: `${formulaPrefix}+AB${currentRow}`,
      docCirrusSharePriceTotal: `${formulaPrefix}AL${currentRow}*G${currentRow}`,
      semdatexFixCosts: persistentState.semdatexFixedCosts,
      biotronikVPImplants: `${formulaPrefix}+K${currentRow}`,
      biotronikVPImplantsTotal: `${formulaPrefix}AP${currentRow}*H${currentRow}`,
      implantMargeOne: `${formulaPrefix}+AP${currentRow}-AO${currentRow}`,
      implantMargeOneTotal: `${formulaPrefix}+AR${currentRow}*H${currentRow}`,
      semdatexEach: `${formulaPrefix}AR${currentRow}*(${persistentState.implantRevenueShare.semdatex}/100)`,
      semdatexTotal: `${formulaPrefix}+AT${currentRow}*H${currentRow}`,
      bioEach: `${formulaPrefix}AR${currentRow}*(${persistentState.implantRevenueShare.biotronik}/100)`,
      bioTotal: `${formulaPrefix}+AV${currentRow}*H${currentRow}`,
      docCirrusEach: `${formulaPrefix}AR${currentRow}*(${persistentState.implantRevenueShare.docCirrus}/100)`,
      docCirrusTotal: `${formulaPrefix}+AX${currentRow}*H${currentRow}`,
      implantMargeFinal: `${formulaPrefix}AU${currentRow}+AW${currentRow}+AY${currentRow}`,
      billPiece: "",
      billTotal: "",
      billToHaveEach: `${formulaPrefix}AO${currentRow}+AT${currentRow}+AX${currentRow}`,
      billToHaveTotal: `${formulaPrefix}BD${currentRow}*H${currentRow}`,
      additionalDemand: ``,
      docCirrusShareEachPast: `${formulaPrefix}+AX${currentRow}`,
      docCirrusShareTotalPast: `${formulaPrefix}BG${currentRow}*H${currentRow}`
    }

    excelInfo.push(obj)
  }


  return excelInfo
}
