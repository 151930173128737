import KeycloakFunctions from "../keycloakFunctions";
import {serverDomain} from "../constants";

/**
 * Fetches the config from the server
 */
export async function getConfig() {
  let response;
  const token = KeycloakFunctions.getToken()
  try {
    response = await fetch(`${serverDomain}/config`, {
      method: 'GET',
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    return await response.json()
  } catch (e) {
    throw new Error('Error while fetching config from server')
  }
}

/**
 * Updates the config on the server
 * @param config
 */
export async function updateConfig(config: any) {
  let response;
  const token = KeycloakFunctions.getToken()
  try {
    response = await fetch(`${serverDomain}/config`, {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(config)
    })

    if (!response.ok) {
      throw new Error(`Could not update config! HTTP status: ${response.status}`);
    }
  } catch (e) {
    throw new Error('Error while updating config')
  }
}
