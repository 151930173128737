import {InCareNetHFFormValues} from "../components/forms/productForms/InCareNetHFProductForm";
import {InCareNetHF, inCareNetHFSchema, InCareNetHFTableData} from "../models/inCareNetHF";
import dayjs from "dayjs";
import {addSoftware, deleteSoftware, getSoftware, updateSoftware} from "../api/software";
import {sumArray} from "../utils/helperFunctions";
import myzod from "myzod";
import {io} from "socket.io-client";
import {activeFieldNames, inActiveFieldNames, serverDomain} from "../constants";
import {GridColDef} from "@mui/x-data-grid";

export default class InCareNetHFService {

  static deviceCountColumns = [
    "activeAbbott",
    "activeBio",
    "activeBoston",
    "activeMedtronic",
    "activeMicroPort",
    "externalSensors",
    "totalActiveImplants",
    "inactiveAbbott",
    "inactiveBio",
    "inactiveBoston",
    "inactiveMedtronic",
    "inactiveMicroPort",
    "totalInactiveImplants",
  ]

  static deviceCountManufacturers = [
    "Abbott",
    "Bio",
    "Boston",
    "Medtronic",
    "MicroPort"
  ]

  /**
   * Triggers a webservice via websocket which refreshes all the counts
   * UI-Changes included
   * For future: write a different function for UI-Changes which triggers that function
   */
  static async refreshCounts(setLoadingProgress: (progress: number) => void, setRefreshingCounts: (refreshing: boolean) => void) {
    let jobDone = false
    const socket = io(serverDomain)

    socket.on('dev-count-done', json => {
      setLoadingProgress(100)
      if (json.error) {
        alert("Refresh was not successful :(")
      } else if (!json.allUpdated) {
        alert("Not all values were updated")
      }
      jobDone = true
    })

    socket.on('dev-count-progress', json => {
      setLoadingProgress(json.progress)
    })

    socket.on('dev-count-fail', json => {
      console.log("Trying to disconnect")
      socket.disconnect()
      setLoadingProgress(100)
      setRefreshingCounts(false)
      alert("Another request is in progress, please try again later. \n" +
        `Current progress of ongoing request: ${parseInt(json.progress)}%`)
      jobDone = true
    })

    socket.emit('refreshCounts')

    while (true) {
      if (jobDone) {
        break
      }
      await new Promise(r => setTimeout(r, 1000));
    }

    setRefreshingCounts(false)
  }


  static async getInCareNetHFs(query?: any): Promise<InCareNetHF[]> {
    const response = await getSoftware(query)

    if (!Array.isArray(response)) {
      throw new Error("Error while fetching inCareNet HF objects")
    }

    return response
  }

  static async addInCareNetHF(icn: InCareNetHF) {
    const result = inCareNetHFSchema.try(icn)

    if (result instanceof myzod.ValidationError) {
      throw result
    }

    if (!(await this.checkUniqueProperties(icn))) {
      alert("URL of inCareNet is not unique!")
      throw new Error("InCareNet values are not unique!")
    }

    console.log('Validation passed. Adding inCareNet HF')
    await addSoftware(icn)
  }

  static async updateInCareNetHF(icn: InCareNetHF) {
    const result = inCareNetHFSchema.try(icn)

    if (result instanceof myzod.ValidationError) {
      throw result
    }

    if (!result._id) {
      throw new Error('Validation Error, it appears the given InCareNetHF object is missing an _id property')
    }

    if (!(await this.checkUniqueProperties(icn))) {
      alert("URL of inCareNet is not unique!")
      throw new Error("InCareNet values are not unique!")
    }

    console.log('Validation passed. Updating inCareNet HF...')
    await updateSoftware(icn)
  }

  static async deleteInCareNetHF(icnId: string) {
    await deleteSoftware(icnId)
  }

  static mapFormToInCareNetHFObject(customerId: string, formObject: InCareNetHFFormValues, icnId?: string): InCareNetHF {
    // TODO refactor customerName in backend
    return {
      ...icnId ? {_id: icnId} : {},
      solName: "incardio-dashboard",
      customerId,
      url: formObject.url,
      token: formObject.token,
      os: formObject.os,
      priceSensorsPerPatient: parseFloat(formObject.priceSensorsPerPatient),
      priceImplantsPerPatient: parseFloat(formObject.priceImplantsPerPatient),
      discount: 0, // Default 0. Property will get removed when excel export is
      getemedHotlineSupport: formObject.getemedHotlineSupport,
      tmzActiveSince: formObject.tmzActiveSince ? formObject.tmzActiveSince.format('YYYY-MM-DD') : ""
    }
  }

  static mapInCareNetHFObjectToFormObject(icnObject: InCareNetHF): InCareNetHFFormValues {
    return {
      getemedHotlineSupport: icnObject.getemedHotlineSupport,
      os: icnObject.os,
      priceImplantsPerPatient: icnObject.priceImplantsPerPatient.toString(),
      priceSensorsPerPatient: icnObject.priceSensorsPerPatient.toString(),
      tmzActiveSince: dayjs(icnObject.tmzActiveSince),
      token: icnObject.token,
      url: icnObject.url
    }
  }

  static async getTableInCareNetHFData(): Promise<InCareNetHFTableData[]> {
    const inCareNetHFs = await getSoftware()
    return inCareNetHFs.map((elem: any) => { // TODO specify elem type
      const totalActiveImplants = sumArray([
        elem.currentDeviceCount.deviceCountActive.Abbott,
        elem.currentDeviceCount.deviceCountActive.Bio,
        elem.currentDeviceCount.deviceCountActive.Boston,
        elem.currentDeviceCount.deviceCountActive.Medtronic,
        elem.currentDeviceCount.deviceCountActive.MicroPort
      ])

      const totalInactiveImplants = sumArray([
        elem.currentDeviceCount.deviceCountInactive.Abbott,
        elem.currentDeviceCount.deviceCountInactive.Bio,
        elem.currentDeviceCount.deviceCountInactive.Boston,
        elem.currentDeviceCount.deviceCountInactive.Medtronic,
        elem.currentDeviceCount.deviceCountInactive.MicroPort
      ])

      return {
        _id: elem._id,
        customerId: elem.customerId, // Necessary to get customer related data
        activeAbbott: elem.currentDeviceCount.deviceCountActive.Abbott,
        activeBio: elem.currentDeviceCount.deviceCountActive.Bio,
        activeBoston: elem.currentDeviceCount.deviceCountActive.Boston,
        activeMedtronic: elem.currentDeviceCount.deviceCountActive.Medtronic,
        activeMicroPort: elem.currentDeviceCount.deviceCountActive.MicroPort,
        customerName: elem.customerName,
        customerNumber: elem.customerNumber,
        externalSensors: elem.currentDeviceCount.deviceCountActive.ExternalSensors,
        getemedHotlineSupport: elem.getemedHotlineSupport,
        inactiveAbbott: elem.currentDeviceCount.deviceCountInactive.Abbott,
        inactiveBio: elem.currentDeviceCount.deviceCountInactive.Bio,
        inactiveBoston: elem.currentDeviceCount.deviceCountInactive.Boston,
        inactiveMedtronic: elem.currentDeviceCount.deviceCountInactive.Medtronic,
        inactiveMicroPort: elem.currentDeviceCount.deviceCountInactive.MicroPort,
        location: elem.location,
        totalActiveImplants,
        totalInactiveImplants,
        implantConnectionRate: getImplantConnectionRate(totalActiveImplants, totalInactiveImplants),
        version: elem.version,
        url: elem.url,
        tmzActiveSince: elem.tmzActiveSince,
        priceImplantsPerPatient: elem.priceImplantsPerPatient,
        priceSensorsPerPatient: elem.priceSensorsPerPatient,
        os: elem.os,
      }
    })

    /**
     * Calculates: implantConnectionRate = totalActive / (totalActive + totalInactive)
     * Returns NaN if totalInactive < 0, which indicates device count fetch error.
     * @param totalActive Total amount of actively monitored implants
     * @param totalInactive Total amount of not actively monitored implants remaining in the inSuite
     */
    function getImplantConnectionRate(totalActive: number, totalInactive: number): number {
      if (totalInactive < 0) { // If device count fetch fails. Remove if totalInactive cannot be lower than 0.
        return NaN
      }

      if ((totalActive + totalInactive) === 0) {
        return 0
      }

      const rate = totalActive / (totalActive + totalInactive)
      return Math.round(parseFloat(rate.toFixed(2)) * 100) // calculate percentage
    }
  }

  static getSumOfDeviceCount(sol: InCareNetHF, isActive: boolean) {
    let sum = 0;

    if (sol.currentDeviceCount) {
      const currentDeviceCount = isActive ? sol.currentDeviceCount.deviceCountActive
        : sol.currentDeviceCount.deviceCountInactive

      for (let [key, value] of Object.entries(currentDeviceCount)) {
        if (key !== 'ExternalSensors') {
          sum += value;
        }
      }

    }

    return sum
  }

  static aggregateDeviceCount(tableData: InCareNetHFTableData[], column: GridColDef): number | null {
    let sum = 0;

    for (const elem of tableData) {

      const value = elem[column.field]

      if (
        typeof value !== 'number' ||
        !(activeFieldNames.includes(column.field) || inActiveFieldNames.includes(column.field))
      ) {
        console.error(`Could not aggregate device counts from ${column.field} with value: ${value?.toString()}`)
        return null
      }

      if (value < 0) {
        continue // Do not add negative values, those indicate error with device count fetch
      }
      sum += value

    }
    return sum;
  }

  static isDeviceCountValid(value: any): { error: boolean, message?: string } {
    if (typeof value === 'number' && value >= 0) {
      return {error: false}
    }
    return {error: true, message: `Device count fetch error: Check URL and Token.`}
  }

  /**
   * Validates the url necessary for ICN device count fetch.
   * Returns "true" if pattern matches, an error string else.
   * @param str
   */
  static validateICNUrl(str: string): boolean | string {
    const urlPattern = new RegExp(
      '^https:\\/\\/' + // mandatory https protocol
      '([a-zA-Z\\d-]+\\.)+[a-zA-Z]{2,}' + // domain name with mandatory TLD
      '(\\:\\d+)?' + // optional port
      '$' // end of string, no path or query allowed
    );

    return urlPattern.test(str) || "Ungültige URL";
  }


  /**
   * This function checks properties which must be unique in the InCareNetHF.
   * At the moment, this is only the "url" property.
   * Returns true if values are unique, false otherwise.
   * @param icn
   */
  static async checkUniqueProperties(icn: InCareNetHF): Promise<boolean> {
    const result = await getSoftware()
    const sameUrl = result.find((elem: any) => {
      return elem.url === icn.url && elem._id !== icn._id
    })
    return !sameUrl
  }
}
