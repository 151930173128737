import myzod, {Infer} from "myzod";

export const implantRevenueShareSchema = myzod.object({
  semdatex: myzod.number(),
  biotronik: myzod.number(),
  docCirrus: myzod.number()
})

export const externalSensorsRevenueShareSchema = myzod.object({
  semdatex: myzod.number(),
  getemed: myzod.number(),
  biotronik: myzod.number(),
  docCirrus: myzod.number()
})

export const configSchema = myzod.object({
  getemedFixedCosts: myzod.number(),
  getemedHotlinePrice: myzod.number(),
  semdatexFixedCosts: myzod.number(),
  implantRevenueShare: implantRevenueShareSchema,
  revenueShare: externalSensorsRevenueShareSchema,
})

export type Config = Infer<typeof configSchema>
export type ImplantRevenueShare = Infer<typeof implantRevenueShareSchema>
export type ExternalSensorsRevenueShare = Infer<typeof externalSensorsRevenueShareSchema>

