import React, {useEffect} from 'react'
import {FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import {useForm} from 'react-hook-form'
import {FormIds} from "../../constants";
import {getEnumKeys, System} from "../../utils/System";

export interface CustomerFormValues {
  name: string
  doctorName: string
  customerNumber: string
  streetAndDwellingNumber: string
  zip: string
  location: string
  biotronikClientName: string
  biotronikCustomerNumber: string
  eTin: string
  biotronikImplantPrice: number
  biotronikExternalSensorsPrice: number
  bostonSAPID: string
  abbottId: string
  microportId: string
  system: string
}

const defaultTextFieldWidth = {
  width: "15rem"
}

interface UpdateCustomerFormProps {
  onSubmit: (data: CustomerFormValues) => void
  /** This function updates the dirty state in the parents component */
  updateDirty: (dirty: boolean) => void
  defaultValues: CustomerFormValues
}

export default function UpdateCustomerForm({onSubmit, defaultValues, updateDirty}: Readonly<UpdateCustomerFormProps>) {

  const form = useForm<CustomerFormValues>({
    defaultValues
  })

  const {register, handleSubmit, formState, getValues, setValue, watch} = form

  const {errors} = formState

  const columnSpacing = 5

  const watchedSystem = watch('system', getValues().system) // Triggers rerender on changed values in select

  useEffect(() => {
    updateDirty(form.formState.isDirty)
  }, [form.formState.isDirty])

  return (
    <form id={FormIds.updateCustomer} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing={2}>
        <Stack
          direction="row"
          spacing={columnSpacing}
        >
          <TextField
            sx={defaultTextFieldWidth}
            id={"name"}
            variant="standard"
            label="Kundenname"
            required
            {...register("name", {
              required: "Kundenname wird benötigt"
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <TextField
            sx={defaultTextFieldWidth}
            id={"doctorName"}
            variant="standard"
            label="Arztname"
            required
            {...register("doctorName", {
              required: "Arztname wird benötigt"
            })}
            error={!!errors.doctorName}
            helperText={errors.doctorName?.message}
          />
          <TextField
            sx={defaultTextFieldWidth}
            id={"customerNumber"}
            variant="standard"
            label="Kundennummer"
            required
            {...register("customerNumber", {
              required: "Kundennummer wird benötigt"
            })}
            error={!!errors.customerNumber}
            helperText={errors.customerNumber?.message}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={columnSpacing}
        >
          <TextField
            sx={defaultTextFieldWidth}
            id={"streetAndDwellingNumber"}
            variant="standard"
            label="Straße und Hausnummer"
            {...register("streetAndDwellingNumber")}
          />
          <TextField
            sx={defaultTextFieldWidth}
            id={"zip"}
            variant="standard"
            label="Postleitzahl"
            {...register("zip")}
          />
          <TextField
            sx={defaultTextFieldWidth}
            id={"location"}
            variant="standard"
            label="Ort"
            required
            {...register("location", {
              required: 'Ort wird benötigt'
            })}
            error={!!errors.location}
            helperText={errors.location?.message}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={columnSpacing}
        >
          <TextField
            sx={defaultTextFieldWidth}
            id={"biotronikClientName"}
            variant="standard"
            label="Biotronik Kundenname"
            required
            {...register("biotronikClientName", {
              required: "Biotronik Kundenname wird benötigt"
            })}
            error={!!errors.biotronikClientName}
            helperText={errors.biotronikClientName?.message}
          />
          <TextField
            sx={defaultTextFieldWidth}
            id={"biotronikCustomerNumber"}
            variant="standard"
            label="Biotronik Kundennummer"
            required
            {...register("biotronikCustomerNumber", {
              required: "Biotronik Kundennummer wird benötigt"
            })}
            error={!!errors.biotronikCustomerNumber}
            helperText={errors.biotronikCustomerNumber?.message}
          />
          <TextField
            sx={defaultTextFieldWidth}
            id={"eTin"}
            variant="standard"
            label="Biotronik eTin"
            required
            {...register("eTin", {
              required: "Biotronik eTin wird benötigt"
            })}
            error={!!errors.eTin}
            helperText={errors.eTin?.message}
          />
          <TextField
            id={"biotronikImplantPrice"}
            label="Biotronik Implantat Preis"
            variant="outlined"
            type="number"
            required
            {...register("biotronikImplantPrice", {
              required: "Biotronik Implantat Preis wird benötigt"
            })}
            error={!!errors.biotronikImplantPrice}
            helperText={errors.biotronikImplantPrice?.message}
          />
          <TextField
            id={"biotronikExternalSensorsPrice"}
            label="Biotronik externe Sensoren Preis"
            variant="outlined"
            type="number"
            required
            {...register("biotronikExternalSensorsPrice", {
              required: "Biotronik externe Sensoren Preis wird benötigt"
            })}
            error={!!errors.biotronikExternalSensorsPrice}
            helperText={errors.biotronikExternalSensorsPrice?.message}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={columnSpacing}
        >
          <TextField
            sx={defaultTextFieldWidth}
            id={"bostonSAPID"}
            variant="standard"
            label="Boston SAP ID"
            {...register("bostonSAPID")}
          />
          <TextField
            sx={defaultTextFieldWidth}
            id={"abbottId"}
            variant="standard"
            label="Abbott ID"
            {...register("abbottId")}
          />
          <TextField
            sx={defaultTextFieldWidth}
            id={"microportId"}
            variant="standard"
            label="Microport ID"
            {...register("microportId")}
          />

          <FormControl>
            <InputLabel id={'system_input_label'}>System</InputLabel>
            <Select
              //holy value
              sx={{width: "31.85rem"}}
              value={watchedSystem}
              onChange={event => setValue('system', event.target.value as string, {shouldDirty: true})}
              label={'System'}
              labelId={'system_input_label'}>
              {getEnumKeys(System).map((value) => {
                return <MenuItem key={value} value={System[value]}>{System[value]}</MenuItem>
              })
              }
            </Select>
          </FormControl>

        </Stack>
      </Stack>
    </form>
  )
}
