import myzod, {Infer} from 'myzod'

export const subDeviceSchema = myzod.object({
  subDeviceNo: myzod.string(),
  einstellung: myzod.string(),
  subDeviceSoftwareVn: myzod.string(),
})

export const deviceSchema = myzod.object({
  _id: myzod.string().optional(),
  deviceName: myzod.string(),
  customerId: myzod.string(),
  manufacturer: myzod.string(),
  licenceStart: myzod.string().optional().required(),
  licenceEnd: myzod.string().optional().required(),
  serialNr: myzod.string(),
  deviceType: myzod.string(),
  softwareVn: myzod.string(),
  subDevice: myzod.array(subDeviceSchema),
  additionalNotes: myzod.string(),
  dateOfActivation: myzod.string(),
  soldToBiotronik: myzod.boolean(),
})

export interface InPaceTableValues {
  _id: string
  customerId: string // Necessary to get customer related data
  customerName: string
  customerNumber: string
  location: string
  serialNr: string
  softwareVn: string
  manufacturer: string
  deviceName: string
  licenceStart: string
  licenceEnd: string
  dateOfActivation: string
  licenceValid: boolean
  additionalNotes: string
  soldToBiotronik: boolean
  deviceType: string
  numberOfSubdevices: number
}

export type SubDevice = Infer<typeof subDeviceSchema>
export type Device = Infer<typeof deviceSchema>
