import React from 'react'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

interface LMDatePickerProps {
  value: any
  required: boolean
  label: string
  error: boolean
  errorHelperText: string | undefined
  /**
   * This should be returned from the react react-hook-forms "register" function
   */
  formRegisterObject: any
  onChange: (value: any) => (void)
}

export default function LMDatePicker(
  {
    value,
    label,
    required,
    error,
    errorHelperText,
    formRegisterObject,
    onChange
  }: LMDatePickerProps
) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DatePicker
        label={label}
        value={value}
        slotProps={{
          textField: {
            required: required,
            error: error,
            helperText: errorHelperText,
          },
        }}
        {...formRegisterObject}
        onChange={onChange}
      />
    </LocalizationProvider>
  )
}

