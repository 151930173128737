import {styled} from "@mui/material/styles";

export const InnerPaperContainer = styled('div')({
  width: "100%",
  display: "flex"
})

export const ButtonContainer = styled('div')({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end"
})
