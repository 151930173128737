import {ReactNode} from 'react';
import {Box, Paper} from '@mui/material';

interface FooterProps {
  children: ReactNode
}

/**
 * This is a footer element which is displayed at a fixed position on the bottom of the website.
 * The footer contains an abort and submit button. The formId must be provided in order to connect the submit button to a form.
 * @param formId
 * @constructor
 */
export default function PageFooter({children}: Readonly<FooterProps>) {
  return (
    <Paper sx={{
      marginTop: 'calc(10% + 60px)',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      display: 'flex',
      justifyContent: 'flex-end',
    }}>
      <Box sx={{
        padding: "1rem 3rem",
        display: 'flex',
        gap: 4
      }}>
        {children}
      </Box>
    </Paper>
  );
};
