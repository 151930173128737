import React, {ReactElement} from 'react'
import PageTitle from "../components/subcomponents/PageTitle";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpdateCustomerForm, {CustomerFormValues} from "../components/forms/UpdateCustomerForm";
import {styled} from "@mui/material/styles";
import {useLocation, useParams} from 'react-router-dom';
import ErrorExclamation from "../components/subcomponents/ErrorExclamation";
import CustomerService from "../services/CustomerService";
import {FormIds, Products} from "../constants";
import CustomTabPanel from "../components/CustomTablePanel";
import InCareNetHFProductForm, {InCareNetHFFormValues} from "../components/forms/productForms/InCareNetHFProductForm";
import InCareNetHFService from "../services/InCareNetHFService";
import {InCareNetHF} from "../models/inCareNetHF";
import {Device} from "../models/device";
import InPaceProductForm, {InPaceProductFormValues} from "../components/forms/productForms/InPaceProductForm";
import InPaceService from "../services/InPaceService";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Snackbar,
  Tab,
  Tabs
} from "@mui/material";

const Spacer = styled("span")({
  width: "100%",
  height: "2rem",
  display: "block"
})

interface TabPanelComposit {
  tab: ReactElement,
  panel: ReactElement,
  productName: string,
  productId: string
}

interface ProductInfo {
  productName: string
  product: InCareNetHF | Device
  productId: string
}

export default function UpdateCustomerPage() {

  const {id} = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const product = searchParams.get('product');
  const {pathname} = useLocation();

  const [error, setError] = React.useState(false)
  const [formDirty, setFormDirty] = React.useState(false)
  const [dirtyProduct, setDirtyProduct] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [initialLoadingComplete, setInitialLoadingComplete] = React.useState(false)
  const [customer, setCustomer] = React.useState<any>(null)
  const [customerRegardingProducts, setCustomerRegardingProducts] = React.useState<any>({})
  const [tabValue, setTabValue] = React.useState(0)

  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState("")
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success")

  async function onCustomerFormSubmit(customerData: CustomerFormValues) {
    try {
      const mappedCustomerObj = CustomerService.mapFormObjectToCustomerObject(customerData)

      if (pathname === "/customers/add") {
        if (await CustomerService.doesCustomerExist(mappedCustomerObj)) {
          setShowSnackbar(true)
          setSnackbarSeverity("error")
          setSnackbarMessage("Kunde existiert bereits, Kundenname und Kundennummer müssen unique sein!")
          return
        }

        await CustomerService.addCustomer(mappedCustomerObj)

        setShowSnackbar(true)
        setSnackbarSeverity("success")
        setSnackbarMessage("Kunden erfolgreich hinzugefügt")
        return
      }

      const customerObject = {
        _id: id,
        ...mappedCustomerObj
      }

      await CustomerService.updateCustomer(customerObject)

      setShowSnackbar(true)
      setSnackbarSeverity("success")
      setSnackbarMessage("Kunden erfolgreich geupdated")
    } catch (e) {
      setShowSnackbar(true)
      setSnackbarSeverity("error")
      setSnackbarMessage("Fehler beim Updaten des Kunden")
    }
  }

  function getInitialTabValue() {
    if (product) {
      const composite = getProducts()
      if (composite.length === 0) {
        return 0
      }
      const index = composite.findIndex((elem) => elem.productId === product)
      if (index === -1) {
        throw new Error(`ProductId: ${product} cannot be found`)
      }
      return index
    }
    return 0
  }

  async function loadData() {
    if (pathname === "/customer/add") {
      setLoading(false)
      return
    }

    if (pathname.includes("/customers/update") && id) {
      let customer
      try {
        customer = await CustomerService.getCustomerById(id)
        const regardingProducts: any = {}
        for (const productType of Products) {
          regardingProducts[productType.name] = await productType.getData({
            customerId: id
          })
        }
        setCustomerRegardingProducts(regardingProducts)
      } catch (e) {
        console.error(e)
      }
      if (customer) {
        setCustomer(customer)
      } else {
        setError(true)
      }
    }
    setLoading(false)
  }

  function handleTabChange(event: React.SyntheticEvent, newValue: number) {
    setTabValue(newValue)
  }

  function getDefaultCustomerFormValues(): CustomerFormValues {
    const defaultFormConfig = {
      name: '',
      doctorName: '',
      customerNumber: '',
      streetAndDwellingNumber: '',
      zip: '',
      location: '',
      biotronikClientName: '',
      biotronikCustomerNumber: '',
      eTin: '',
      biotronikImplantPrice: 0,
      biotronikExternalSensorsPrice: 0,
      bostonSAPID: '',
      abbottId: '',
      microportId: '',
      system: ''
    }

    if (pathname === "/customer/add") {
      return defaultFormConfig
    }

    return customer === null ? defaultFormConfig : CustomerService.mapCustomerObjectToFormObject(customer)
  }

  function getProductFormId() {
    const tabs = getProducts()
    if (tabs.length === 0) {
      return ""
    }
    switch (tabs[tabValue].productName) {
      case "inCareNetHF":
        return FormIds.updateInCareNetHF
      case "inPace":
        return FormIds.updateInPace
      default:
        return ""
    }
  }

  React.useEffect(() => {
    loadData().then(() => {
      setInitialLoadingComplete(true)
    })
  }, [tabValue])

  React.useEffect(() => {
    setTabValue(getInitialTabValue())
  }, [initialLoadingComplete])

  function renderCustomerDetails() {
    return error ?
      <ErrorExclamation message="Error while fetching customer details"/> :
      <UpdateCustomerForm onSubmit={onCustomerFormSubmit} defaultValues={getDefaultCustomerFormValues()}
                          updateDirty={setFormDirty}/>
  }

  async function handleProductFormSubmit(formObject: InCareNetHFFormValues | InPaceProductFormValues) {
    const tabs = getProducts()
    const productId = tabs[tabValue].productId
    if (!id) {
      return
    }

    switch (tabs[tabValue].productName) {
      case "inCareNetHF":
        try {
          const inCareNetHFObject = InCareNetHFService.mapFormToInCareNetHFObject(id, formObject as InCareNetHFFormValues, productId)
          await InCareNetHFService.updateInCareNetHF(inCareNetHFObject)

          setShowSnackbar(true)
          setSnackbarSeverity("success")
          setSnackbarMessage("inCareNetHF-Produkt erfolgreich geupdated")
        } catch (e) {
          console.error(e)
          setShowSnackbar(true)
          setSnackbarSeverity("error")
          setSnackbarMessage("Fehler beim Updaten des inCareNetHF-Produkts")
        }
        break
      case "inPace":
        try {
          const inPaceObject = InPaceService.mapFormObjectToDeviceObject(id, formObject as InPaceProductFormValues, productId)
          await InPaceService.updateDevice(inPaceObject)

          setShowSnackbar(true)
          setSnackbarSeverity("success")
          setSnackbarMessage("inPace-Produkt erfolgreich geupdated")
        } catch (e) {
          console.error(e)
          setShowSnackbar(true)
          setSnackbarSeverity("error")
          setSnackbarMessage("Fehler beim Updaten des inPace-Produkts")
        }
        break
      default:
        return
    }
  }

  function getProducts(): ProductInfo[] {
    const products: ProductInfo[] = []

    if (Object.keys(customerRegardingProducts).length === 0) {
      return products
    }

    Products.forEach((productType) => {
      customerRegardingProducts[productType.name].forEach((product: InCareNetHF | Device) => {
        if (!product._id) {
          throw Error("ProductId could not be gathered")
        }
        products.push({
          productName: productType.name,
          product: product,
          productId: product._id
        })
      })
    })
    return products
  }

  function getProductTabPanelComposite(products: ProductInfo[]): TabPanelComposit[] {
    let tabsAndPanels: TabPanelComposit[] = []

    if (products.length === 0) {
      return tabsAndPanels
    }

    tabsAndPanels = products.map((elem, index) => {
      let panelForm
      switch (elem.productName) {
        case "inCareNetHF":
          panelForm = (<InCareNetHFProductForm onSubmit={handleProductFormSubmit}
                                               defaultValues={InCareNetHFService.mapInCareNetHFObjectToFormObject(elem.product as InCareNetHF)}
                                               updateDirty={setDirtyProduct}/>)
          break
        case "inPace":
          panelForm = (<InPaceProductForm onSubmit={handleProductFormSubmit}
                                          defaultValues={InPaceService.mapDeviceObjectToFormObject(elem.product as Device)}
                                          updateDirty={setDirtyProduct}/>)
          break
        default:
          break
      }
      return {
        tab: (<Tab label={`${elem.productName} ${index + 1}`} key={elem.productId}/>),
        panel: (
          <CustomTabPanel index={index} value={tabValue} key={elem.productId}>
            {panelForm}
          </CustomTabPanel>
        ),
        productName: elem.productName,
        productId: elem.productId
      }
    })

    return tabsAndPanels
  }

  return (
    <div className={"base-container"}>
      <PageTitle>Daten bearbeiten</PageTitle>
      <Divider/>
      <Spacer/>
      {/* Customer section*/}
      <Accordion defaultExpanded={!product}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          id="update-customer-panel"
        >
          Kundendaten
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{paddingBottom: "1rem"}}>
            {loading ?
              <CircularProgress/> :
              renderCustomerDetails()
            }
          </Box>
        </AccordionDetails>
        <AccordionActions>
          <Button
            sx={{marginRight: ".5rem"}}
            type="submit"
            form={FormIds.updateCustomer}
            disabled={!formDirty}
          >Speichern</Button>
        </AccordionActions>
      </Accordion>
      <Spacer/>
      {/* Product section*/}

      {
        pathname === "/customers/add" ? <div/> :
          <Accordion defaultExpanded={!!product}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              id="update-products-panel"
            >
              Produktdaten
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  {getProductTabPanelComposite(getProducts()).map((elem) => {
                    return elem.tab
                  })}
                </Tabs>
              </Box>
              {getProductTabPanelComposite(getProducts()).map((elem) => {
                return elem.panel
              })}
            </AccordionDetails>
            <AccordionActions>
              <Button
                sx={{marginRight: ".5rem"}}
                type="submit"
                form={getProductFormId()}
                disabled={!dirtyProduct}
              >Speichern</Button>
            </AccordionActions>
          </Accordion>
      }

      <Snackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity as "success" | "error"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </div>
  )
}
