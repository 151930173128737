import {serverDomain} from "../constants";
import Query from "../models/Query";

import {locale} from "dayjs";

export function timeStamp() {
  const date = new Date();
  return date.toLocaleDateString(locale('de-DE')) + "/" + date.toLocaleTimeString('de-DE', {hour12: false});
}

export function getGETUrl(endpoint: string, query?: Query) {
  if (!query) {
    return `${serverDomain}/${endpoint}`
  }

  let url = `${serverDomain}/${endpoint}?`
  Object.keys(query).forEach((key) => {
    if (typeof query[key] !== 'string') {
      return
    }
    url = url + `${key}=${query[key]}&`
  })
  url = url.slice(0,-1) // Cut off last "&"
  return url
}

export function sumArray(arr: number[]): number {
  return arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
