import {CustomerFormValues} from "../components/forms/UpdateCustomerForm";
import {Customer, customerSchema, CustomerTableData} from "../models/customer";
import {
  addCustomer,
  deleteCustomer,
  doesCustomerAlreadyExist,
  getCustomerById,
  getCustomers,
  updateCustomer
} from "../api/customers";
import myzod from "myzod";

export default class CustomerService {

  static async getAllCustomers(): Promise<Customer[]> {
    const customers = await getCustomers()

    if (!Array.isArray(customers)) {
      throw new Error("Fetched Customers are not an array")
    }

    return customers as Customer[]
  }

  static async getCustomerById(id: string): Promise<Customer> {
    const customer = await getCustomerById(id)

    if (typeof customer !== "object" || !customer._id) {
      throw new Error("Fetched customer is not valid")
    }

    return customer as Customer
  }

  static async addCustomer(customer: Customer) {
    const result = customerSchema.try(customer)

    if (result instanceof myzod.ValidationError) {
      throw result
    }

    if (!(await this.checkUniqueProperties(customer))) {
      alert("Some values which must be unique are not unique! Customer will not be added!")
      throw new Error("Customer values are not unique!")
    }

    console.log('Validation passed. Adding customer')
    await addCustomer(customer)
  }

  static async updateCustomer(customer: Customer) {
    const result = customerSchema.try(customer)

    if (result instanceof myzod.ValidationError) {
      throw result
    }

    if (!(await this.checkUniqueProperties(customer))) {
      alert("Some values which must be unique are not unique! Customer will not be updated!")
      throw new Error("Customer values are not unique!")
    }

    console.log('Validation passed. Updating customer')
    await updateCustomer(customer)
  }

  static async deleteCustomer(customerId: string, safe = true) {
    await deleteCustomer(customerId, safe)
  }

  static async getCustomerTableData(): Promise<CustomerTableData[]> {
    const customers: Customer[] = await this.getAllCustomers()
    return customers.map((elem) => {
      if (!elem._id) {
        throw new Error(`Data field: ${elem} does not contain ._id parameter. \n` +
          "Without the identifier, the object cannot be updated or deleted")
      }
      return {
        _id: elem._id,
        name: elem.name,
        doctorName: elem.doctorName,
        customerNumber: elem.customerNumber,
        postalCode: elem.address.postalCode,
        location: elem.address.location,
        street: elem.address.street,
        system: elem.system,
        biotronikClientName: elem.biotronikClientName,
        biotronikCustomerNumber: elem.biotronikCustomerNumber,
        eTin: elem.eTin,
        biotronikImplantPrice: Number(elem.biotronikImplantPrice),
        biotronikExternalSensorsPrice: Number(elem.biotronikExternalSensorsPrice),
        bostonSAPID: elem.bostonSAPID,
      }
    })
  }

  static mapCustomerObjectToFormObject(customerObject: any): CustomerFormValues {
    return {
      name: customerObject.name,
      doctorName: customerObject.doctorName,
      customerNumber: customerObject.customerNumber,
      streetAndDwellingNumber: customerObject.address.street,
      zip: customerObject.address.postalCode,
      location: customerObject.address.location,
      biotronikClientName: customerObject.biotronikClientName,
      biotronikCustomerNumber: customerObject.biotronikCustomerNumber,
      eTin: customerObject.eTin,
      biotronikImplantPrice: customerObject.biotronikImplantPrice,
      biotronikExternalSensorsPrice: customerObject.biotronikExternalSensorsPrice,
      bostonSAPID: customerObject.bostonSAPID,
      abbottId: '', //TODO add this in backend
      microportId: '', //TODO add this in backend
      system: customerObject.system
    }
  }

  static mapFormObjectToCustomerObject(formObject: CustomerFormValues): Customer {
    return {
      name: formObject.name,
      doctorName: formObject.doctorName,
      customerNumber: formObject.customerNumber,
      address: {
        street: formObject.streetAndDwellingNumber,
        postalCode: formObject.zip,
        location: formObject.location
      },
      biotronikClientName: formObject.biotronikClientName,
      biotronikCustomerNumber: formObject.biotronikCustomerNumber,
      eTin: formObject.eTin,
      biotronikImplantPrice: String(formObject.biotronikImplantPrice),
      biotronikExternalSensorsPrice: String(formObject.biotronikExternalSensorsPrice),
      bostonSAPID: formObject.bostonSAPID,
      exportCustomer: false,
      location: formObject.location, // TODO unnecessary remove later
      system: formObject.system,
    } // TODO add the missing form fields: abbottId, microportId, system
  }

  static async doesCustomerExist(mappedCustomerObj: Customer) {
    const doesExist = await doesCustomerAlreadyExist(mappedCustomerObj)
    return doesExist.customerExists;
  }

  /**
   * This function checks properties which must be unique in the Customer object.
   * Returns true if values are unique, false otherwise.
   * @param customer
   */
  static async checkUniqueProperties(customer: Customer) {
    const result = await getCustomers()
    const notUnique = result.find((elem: any) => {
      return (elem.name === customer.name || elem.customerNumber === customer.customerNumber) && elem._id !== customer._id
    })
    return !notUnique
  }
}
