import React from 'react'
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import ButtonWithMargin from "../subcomponents/Buttons/ButtonWithMargin";

interface DeleteCustomerDialogProps {
  fullDeleteHandler: () => void
  safeDeleteHandler: () => void
  refreshFunction: () => void
  disabled?: boolean
}
export default function DeleteCustomerDialog({fullDeleteHandler, safeDeleteHandler, refreshFunction, disabled=false} : Readonly<DeleteCustomerDialogProps>) {

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function fullDeleteCustomer() {
    await fullDeleteHandler()
    setOpen(false)
    await refreshFunction()
  }

  async function safeDeleteCustomer() {
    await safeDeleteHandler()
    setOpen(false)
    await refreshFunction()
  }

  return (
    <React.Fragment>
      <ButtonWithMargin
        variant="contained"
        color="secondary"
        disabled={disabled}
        onClick={handleClickOpen}
      >
        Löschen
      </ButtonWithMargin>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            textAlign: "center"
          }}
        >
          {"Kunde wirklich löschen?"}
        </DialogTitle>
        <DialogContent>
          {/* TODO Safe delete is disabled because unassigned products cannot be edited*/}
          <ButtonWithMargin
            color={"secondary"}
            variant="contained"
            onClick={safeDeleteCustomer}
            disabled={true}
          >
            Sichere-Löschung
          </ButtonWithMargin>
          <ButtonWithMargin
            color={"secondary"}
            variant="contained"
            onClick={fullDeleteCustomer}
          >
            Vollständige Löschung
          </ButtonWithMargin>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
