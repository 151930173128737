export enum System {
  STANDARD_MICROSERVER_PERFORMANCE = 'Standard Microserver (Performance)',
  STANDARD_MICROSERVER_BASIC = 'Standard Microserver (Basic)',
  VM = 'VM (Virtuelle Maschine)',
  RACK_19_INCH = '19" Rack',
  PERFORMANCE_19_INCH = '19" Performance',
  TOWER_PERFORMANCE_19_INCH = '19" Tower Performance',
}

export function getEnumKeys<
  T extends string,
  TEnumValue extends string | number,
>(enumVariable: { [key in T]: TEnumValue }) {
  return Object.keys(enumVariable) as Array<T>;
}
