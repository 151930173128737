import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import KeycloakFunctions from "./keycloakFunctions";

const env_stage = JSON.stringify(process.env.REACT_APP_ENV_STAGE);
let dev = env_stage === "\"development\"";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const renderedApp = () => {
  root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  );
}

if (!dev) {
  KeycloakFunctions.initKeycloak(renderedApp);
} else {
  renderedApp()
}
