import {GridColDef, GridRenderCellParams, GridRowSelectionModel} from '@mui/x-data-grid'
import {CustomerTableColumns, InCareNetTableColumns, InPaceTableColumns} from "../constants";
import Column from "../models/Column";
import '../App.css'
import {Box} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import LMTable, {TableOptions} from "./Tables/LMTable";
import TooltipWarning from "./subcomponents/TooltipIcons/TooltipWarning";

export default class TableFactory {

  private static createColumns(subject: string, columFields: string[]): GridColDef[] {
    switch (subject) {
      case "inCareNetHF":
        return this.filterColumnsAndApplyStyles(InCareNetTableColumns, columFields)
      case "inPace":
        return this.filterColumnsAndApplyStyles(InPaceTableColumns, columFields)
      case "customers":
        return this.filterColumnsAndApplyStyles(CustomerTableColumns, columFields)
      default: //TODO add other subjects
        return []
    }
  }

  private static filterColumnsAndApplyStyles(columns: Column[], columFields: string[]): GridColDef[] {
    return this.applyStyles(this.filterColumns(columns, columFields))
  }

  private static filterColumns(columns: Column[], columFields: string[]) {
    return columns.filter((column) => columFields.includes(column.field))
  }

  private static applyStyles(columns: Column[]) {
    return columns.map((column) => {
      // This fragment sets the width of the column. "flex" is prioritized before "width"
      let columnWidth = 100 // Default static column width
      let staticWidth = true
      if (column.flex) {
        columnWidth = column.flex
        staticWidth = false
      } else if (column.width) {
        columnWidth = column.width
      }

      return {
        ...column,
        ...(column.iconFromBool ? {renderCell: (params: GridRenderCellParams) => {
            return (<Box sx={{marginTop: "7px"}}>{params.value ? <DoneIcon /> : <CloseIcon />}</Box>)
          }} : {}),
        ...(column.warnIconIfError ? {
          renderCell: (params: GridRenderCellParams) => {
            if (column.warnIconIfError === undefined) { // This check is only for the IDE which needs a check if undefined for warnIconIfError
              return params.value
            }
            const check = column.warnIconIfError(params.value)
            return (
              check.error ?
                <Box sx={{marginTop: "7px"}}>
                  <TooltipWarning tooltipMessage={check.message}/>
                </Box> :
                <div>{params.value}</div>
            )
          }
        } : {}),
        ...(staticWidth ? { width: columnWidth } : { flex: columnWidth }),
      }
    })
  }

  static createTable(
    subject: string,
    initialColumnFields: string[],
    rows: any[],
    selectionModel: GridRowSelectionModel,
    selectionHandler: (rowSelectionModel: GridRowSelectionModel) => void,
    options: TableOptions ={}
  ) {

    return (
      <LMTable
        rows={rows}
        columns={this.createColumns(subject, initialColumnFields)}
        options={options}
        selectionModel={selectionModel}
        selectionHandler={selectionHandler}
      />
    )
  }
}
