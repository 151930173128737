import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField} from "@mui/material";

export interface SubDeviceFormValues {
  subDeviceNo: string
  einstellung: string
  subDeviceSoftwareVn: string
}

interface SubDeviceDialogProps {
  type: "add" | "update",
  /** This function updates the dirty state in the parents component */
  updateDirty: (dirty: boolean) => void,
  subDeviceDefaultValues: SubDeviceFormValues
  validateSubDeviceId: (id: string) => boolean
  submitOperation: (data: SubDeviceFormValues) => void
  disabled?: boolean
}

export default function SubDeviceDialogButton({
                                                type,
                                                subDeviceDefaultValues,
                                                submitOperation,
                                                validateSubDeviceId,
                                                disabled = false,
                                                updateDirty
                                              }: Readonly<SubDeviceDialogProps>) {

  const [open, setOpen] = React.useState(false)
  const [subDevice, setSubDevice] = React.useState<SubDeviceFormValues>(subDeviceDefaultValues)

  React.useEffect(() => {
    setSubDevice(subDeviceDefaultValues)
  }, [subDeviceDefaultValues])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!validateSubDeviceId(subDevice.subDeviceNo)) {
      alert("Sub Gerät Nummer muss eindeutig/einzigartig sein!")
      return
    }
    submitOperation(subDevice)
    setOpen(false);
    updateDirty(true)
  };

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} disabled={disabled}>
        {type === "add" ? "Hinzufügen" : "Bearbeiten"}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        maxWidth={'xl'}
      >
        <DialogTitle>
          {"Sub Gerät hinzufügen"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              id={"subDeviceNo"}
              variant="standard"
              defaultValue={subDeviceDefaultValues.subDeviceNo}
              required
              label="Sub Gerät Nummer"
              onChange={(event) => setSubDevice({...subDevice, subDeviceNo: event.target.value})}
            />
            <TextField
              id={"einstellung"}
              variant="standard"
              defaultValue={subDeviceDefaultValues.einstellung}
              required
              label="Einstellung"
              onChange={(event) => setSubDevice({...subDevice, einstellung: event.target.value})}
            />
            <TextField
              id={"subDeviceSoftwareVn"}
              variant="standard"
              defaultValue={subDeviceDefaultValues.subDeviceSoftwareVn}
              label="Sub Gerät Versionsnummer"
              required
              onChange={(event) => setSubDevice({...subDevice, subDeviceSoftwareVn: event.target.value})}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false);
          }}>Abbrechen</Button>
          <Button onClick={() => {
            if (subDevice.subDeviceNo !== "" && subDevice.einstellung !== "" && subDevice.subDeviceSoftwareVn !== "") {
              handleClose()
            } else {
              alert('Bitte fülle alle felder aus')
            }
          }} autoFocus>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
