import React from 'react';
import { Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

const Circle = styled('div')(({ theme }: { theme: Theme }) => ({
  width: 100,
  height: 100,
  borderRadius: '50%',
  backgroundColor: 'white',
  border: '2px solid black', // Black border
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const Exclamation = styled('span')({
  color: 'black', // Black exclamation mark
  fontSize: 48,
  fontWeight: 'bold',
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

interface ErrorExclamationProps {
  message?: string
}

export default function ErrorExclamation({ message }: Readonly<ErrorExclamationProps>) {
  return (
    <Container>
      <Circle>
        <Exclamation>!</Exclamation>
      </Circle>
      <Typography variant="subtitle1">{message || "Error"}</Typography>
    </Container>
  );
}
