import React, { ReactNode } from "react"
import {styled} from "@mui/material/styles";
import {Paper} from "@mui/material";

const DemoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: "2rem",
  ...theme.typography.body2,
  textAlign: 'center',
  minHeight: "30rem"
}));

interface TablePaperProps {
  children: ReactNode;
}

export function TablePaper({children} : Readonly<TablePaperProps>) {

  return (
    <DemoPaper>
      {children}
    </DemoPaper>
  )
}
