import myzod, {Infer} from 'myzod'

const addressSchema = myzod.object({
  street: myzod.string(),
  postalCode: myzod.string(),
  location: myzod.string(),
})

export const customerSchema = myzod.object({
  _id: myzod.string().optional(),
  name: myzod.string().optional().required(),
  customerNumber: myzod.string().optional().required(),
  doctorName: myzod.string().optional().required(),
  biotronikClientName: myzod.string().optional().required(),
  biotronikCustomerNumber: myzod.string().optional().required(),
  eTin: myzod.string().optional().required(),
  biotronikExternalSensorsPrice: myzod.string(),
  biotronikImplantPrice: myzod.string(),
  location: myzod.string(),
  system: myzod.string(),
  exportCustomer: myzod.boolean(),
  address: addressSchema,
  bostonSAPID: myzod.string(),
})

export interface CustomerTableData {
  _id: string
  name: string
  doctorName: string
  customerNumber: string
  postalCode: string
  location: string
  street: string
  system: string
  biotronikClientName: string
  biotronikCustomerNumber: string
  eTin: string
  biotronikImplantPrice: number
  biotronikExternalSensorsPrice: number
  bostonSAPID: string
}


export type Address = Infer<typeof addressSchema>
export type Customer = Infer<typeof customerSchema>
