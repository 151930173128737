import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ButtonWithMargin from "./ButtonWithMargin";

interface AddButtonProps {
    onClick: () => void
}

export default function AddButton({onClick}: Readonly<AddButtonProps>){

  return (
    <ButtonWithMargin
      variant="contained"
      startIcon={<AddIcon />}
      onClick={onClick}
    >
      Hinzufügen
    </ButtonWithMargin>
  )
}
